import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

declare var toastr: any;
@Component({
  selector: 'app-add-attendance-policy',
  templateUrl: './add-attendance-policy.component.html',
  styleUrls: ['./add-attendance-policy.component.css'],
})
export class AddAttendancePolicyComponent implements OnInit {
  attendanceFlowPolicy: FormGroup;
  policyId: any;
  isAdd = false;

  constructor(
    private fb: FormBuilder,
    private ngxLoder: NgxUiLoaderService,
    private rostarService: RosterServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
  onSubmit(): any {
    console.log(
      'this.attendanceFlowPolicy.value',
      this.attendanceFlowPolicy.value,
    );
    // return;
    this.isAdd = true;
    if (this.attendanceFlowPolicy.invalid) {
      return;
    }
    if (this.policyId) {
      this.ngxLoder.start();
      this.rostarService
        .updateAttendencePolicy(this.attendanceFlowPolicy.value, this.policyId)
        .subscribe(
          (data) => {
            this.isAdd = false;
            this.ngxLoder.stop();
            toastr.success('Updated Successfully');
            this.router.navigate(['/roster/attendance-policy']);
          },
          (err) => {
            toastr.error(err.error.error);
            this.ngxLoder.stop();
          },
        );
    } else {
      this.ngxLoder.start();
      this.rostarService
        .submitAttendencePolicy(this.attendanceFlowPolicy.value)
        .subscribe(
          (data) => {
            this.isAdd = false;
            this.ngxLoder.stop();
            toastr.success('Added Successfully');
            this.router.navigate(['/roster/attendance-policy']);
          },
          (err) => {
            toastr.error(err.error.error);
            this.ngxLoder.stop();
          },
        );
    }
  }
  // _onlyNumeric(e): any {
  //   var charCode = (e.which) ? e.which : e.keyCode;
  //   if ((charCode !== 46 && charCode > 31) && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }
  getAttendencePolicyById(): any {
    this.ngxLoder.start();
    this.rostarService.getAttendencePolicyById(this.policyId).subscribe(
      (data) => {
        this.attendanceFlowPolicy.patchValue({
          code: data.code,
          name: data.name,
          description: data.description,
          status: data.status,
          early_in_limit: data.early_in_limit,
          early_in_type: data.early_in_type,
          late_in_limit: data.late_in_limit,
          late_in_type: data.late_in_type,
          absent_in_limit: data.absent_in_limit,
          absent_in_type: data.absent_in_type,
          early_out_limit: data.early_out_limit,
          early_out_type: data.early_out_type,
          late_out_limit: data.late_out_limit,
          late_out_type: data.late_out_type,
          absent_out_limit: data.absent_out_limit,
          absent_out_type: data.absent_out_type,
          overtime_in_limit: data.overtime_in_limit,
          overtime_in_type: data.overtime_in_type,
          overtime_out_limit: data.overtime_out_limit,
          overtime_out_type: data.overtime_out_type,
          early_in_color: data.early_in_color,
          late_in_color: data.late_in_color,
          absent_in_color: data.absent_in_color,
          overtime_in_color: data.overtime_in_color,
          early_out_color: data.early_out_color,
          late_out_color: data.late_out_color,
          absent_out_color: data.absent_out_color,
          overtime_out_color: data.overtime_out_color,
        });
        this.ngxLoder.stop();
      },
      (err) => {
        toastr.error(err.error.error);
        this.ngxLoder.stop();
      },
    );
  }
  ngOnInit(): void {
    this.policyId = Number(this.route.snapshot.queryParamMap.get('id'));
    if (this.policyId) {
      this.getAttendencePolicyById();
    }
    this.attendanceFlowPolicy = this.fb.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      status: [true, Validators.required],
      early_in_limit: [''],
      early_in_type: ['min'],
      late_in_limit: [''],
      late_in_type: ['min'],
      absent_in_limit: [''],
      absent_in_type: ['min'],
      early_out_limit: [''],
      early_out_type: ['min'],
      late_out_limit: [''],
      late_out_type: ['min'],
      absent_out_limit: [''],
      absent_out_type: ['min'],
      overtime_in_limit: [''],
      overtime_in_type: ['hrs'],
      overtime_out_limit: [''],
      overtime_out_type: ['hrs'],
      early_in_color: ['#000000'],
      late_in_color: ['#000000'],
      absent_in_color: ['#000000'],
      overtime_in_color: ['#000000'],
      early_out_color: ['#000000'],
      late_out_color: ['#000000'],
      absent_out_color: ['#000000'],
      overtime_out_color: ['#000000'],
    });
  }
}
