import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import { LabParameterService } from '../../../services/lab-parameter.service';
import { LinkLabParameterService } from '../../../services/link-lab-parameter.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-link-lab-parameter',
  templateUrl: './link-lab-parameter.component.html',
  styleUrls: ['./link-lab-parameter.component.css'],
})
export class LinkLabParameterComponent implements OnInit {
  LabParameterForm: FormGroup;
  SearchParameterForm: FormGroup;
  searchSubmitted = false;
  labParameters = [];
  savedLabParameters = [];
  labParameter: any;
  labTest: any;
  labTestListData = [];
  labSearchResult = false;
  testId: number;
  labConfigurations = [];
  labTestSubmitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private labParameterService: LabParameterService,
    private linkLabParameterService: LinkLabParameterService,
    private labService: LabconfigurationService,
  ) {}
  ngOnInit(): void {
    this.ngxLoader.start();
    this.setTestId();
    this.SearchParameterForm = this.formBuilder.group({
      search_title: [''],
      search_code: ['', Validators.required],
    });

    $('html').click(function () {
      $('.bootstrap-select.open').removeClass('open');
      $('.dropdown-menu').removeClass('show');
    });

    // setTimeout(() => {
    //   $('.selectpicker').selectpicker();
    // }, 500);

    this.addEmptyParameters();
    this.fetchLabTest();
    this.fetchAllLabPaarmeter();
    this.fetchLabConfigurators();
  }
  fetchLabConfigurators(): any {
    this.ngxLoader.start();
    this.labService.getLabSpecificConfigurator('heading').subscribe(
      (data) => {
        // console.log(data);
        this.labConfigurations = data.lab_configurations;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#labConfig').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  fetchAllLabPaarmeter(): any {
    this.labParameters = [];
    const labTests = '#labTests';
    setTimeout(() => {
      $(labTests).selectpicker('refresh');
    }, 500);

    this.ngxLoader.start();
    this.labParameterService.fetch_all_parameters('', '', '', '').subscribe(
      (data) => {
        // console.log(data);
        this.labParameters = data.lab_assessments;
        this.ngxLoader.stop();

        setTimeout(() => {
          $(labTests).selectpicker('refresh');
          $('#parameters').selectpicker('refresh');
          $('.selectpicker').selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $(labTests).selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  setTestId(): any {
    this.testId = Number(this.route.snapshot.queryParamMap.get('id'));
  }

  fetchLabTest(): any {
    // this.ngxLoader.start();
    this.linkLabParameterService.searchLabTest(this.testId).subscribe(
      (data) => {
        // console.log(data);
        this.labTest = data.lab_investigation;
        this.savedLabParameters = data.lab_assessments;
        if (this.savedLabParameters.length > 0) {
          this.savedLabParametersArray();
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  addEmptyParameters(): any {
    this.LabParameterForm = this.formBuilder.group({
      parametersArray: this.formBuilder.array([this.addLabParameterGroup()]),
    });
  }
  setLabParameters(data): any {
    return this.formBuilder.group({
      id: [data.id],
      title: [data.title],
      parameter_code: [data.parameter_code],
      is_active: [data.is_active],
      uom: [data.uom],
      specimen: [data.specimen],
      result_type1: [data.result_type1],
      result_type2: [data.result_type2],
      parameter_type: [data.parameter_type],
      lab_configuration_id: [data.lab_configuration_id || ''],
      primary_id: [data.primary_id || ''],
      sort_order: [data.sort_order, Validators.required],
    });
  }
  addLabParameterGroup(): any {
    return this.formBuilder.group({
      id: [''],
      title: [''],
      parameter_code: [''],
      is_active: [''],
      uom: [''],
      specimen: [''],
      result_type1: [''],
      result_type2: [''],
      parameter_type: [''],
      lab_configuration_id: [''],
      primary_id: [''],
      sort_order: ['', Validators.required],
    });
  }
  get labParametersArray(): any {
    return this.LabParameterForm.get('parametersArray') as FormArray;
  }
  setParameterCode(labParameterId): any {
    this.labParameter = this.labParameters.filter(
      (t) => t.id === Number(labParameterId),
    )[0];
    this.SearchParameterForm.controls.search_code.setValue(
      this.labParameter.parameter_code,
    );
    this.searchLabParmeter();
  }

  savedLabParametersArray(): any {
    this.labParametersArray.removeAt(0);
    for (let j = 0; j < this.savedLabParameters.length; j++) {
      this.labParametersArray.push(
        this.setLabParameters(this.savedLabParameters[j]),
      );
    }
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 1000);
  }
  editLabParameters(): any {
    if (this.labParametersArray.value[0].parameter_code === '') {
      this.labParametersArray.removeAt(0);
    }
    this.labParametersArray.push(this.setLabParameters(this.labParameter));
  }
  removeLabParameter(accessIndex): any {
    if (this.labParametersArray.length > 1) {
      this.labParametersArray.removeAt(accessIndex);
    }
  }
  get parameterCode(): any {
    return this.SearchParameterForm.controls.search_code.value;
  }
  searchLabParmeter(): any {
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 200);
    this.searchSubmitted = true;
    if (this.SearchParameterForm.invalid) {
      return;
    }
    const code = this.parameterCode;
    this.labParameter = this.labParameters.filter(
      (t) => t.parameter_code === code,
    )[0];
    if (this.labParameter) {
      this.SearchParameterForm.controls.search_title.setValue(
        this.labParameter.id,
      );
      this.ngxLoader.start();
      this.editLabParameters();
    } else {
      toastr.error('No Lab parameter with this code exist');
    }
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 200);
    this.ngxLoader.stop();
  }
  checkIfArrayIsUnique(myArray): any {
    const sortArray = [];
    for (let i = 0; i < myArray.length; i++) {
      sortArray.push(myArray[i].sort_order);
    }
    return sortArray.length === new Set(sortArray).size;
  }
  linkLabParameters(): any {
    for (let i = 0; i < this.labParametersArray.value.length; i++) {
      if (this.labParametersArray.value[i].sort_order === null) {
        toastr.error('Add Sort order');
        return;
      }
    }
    if (this.checkIfArrayIsUnique(this.labParametersArray.value) === false) {
      toastr.error('Sort order can be unique');
      return;
    }
    this.ngxLoader.start();
    this.linkLabParameterService
      .linkLabParameters(this.labParametersArray.value, this.testId)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success('Successfully linked Parameters');
          this.router.navigate(['lab/test']);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  onlyNumeric(event: any): any {
    if (event.charCode != 46) {
      const pattern = /^\d{1,6}(\.\d{1,2})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
  }
  get s(): any {
    return this.SearchParameterForm.controls;
  }
}
