import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { formatDate, Location } from '@angular/common';
import * as moment from 'moment';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { EmployeeRegistrationService } from '../../../services/employee-registration.service';
import { PatientService } from '../../../services/patient.service';
import { DialysisService } from '../../../services/dialysis.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-dialysis-request',
  templateUrl: './dialysis-request.component.html',
  styleUrls: ['./dialysis-request.component.css'],
})
export class DialysisRequestComponent implements OnInit {
  patient: any;
  patients: any;
  isPatients = false;
  DialysisRequestForm: FormGroup;
  submitted = false;
  current_url = '';
  current_environment: string;
  available_slots: [];
  dialysis_request_id: number;
  dialysis_request = {};
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  doctorsAll = [];
  doctor = [];
  department = [];
  urgency = [];
  screenName = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private dialysisService: DialysisService,
    private hospitalDoctorsService: HospitaldoctorService,
    private departmentService: DepartmentsbyhospitalService,
    private employeeService: EmployeeRegistrationService,
    private _location: Location,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): any {
    this.current_url = this.router.url;
    this.ngxLoader.stop();

    this.DialysisRequestForm = this.formBuilder.group({
      advised_by_id: ['', Validators.required],
      department_id: ['', Validators.required],
      suggested_date: [this.today, Validators.required],
      available_slot_id: ['', Validators.required],
      urgency_id: ['', Validators.required],
    });

    this.current_environment = environment.rorUrl;
    this.fetchAvailableSlots();
    this.fetchDoctor();
    this.fetchDepartments();
    this.fetchUrgency();
    this.patientFetchByMRN();
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  patientFetchByMRN(): any {
    this.ngxLoader.start();
    this.patientService
      .patientFetchByMRN(this.route.snapshot.paramMap.get('mrn'))
      .subscribe(
        (data) => {
          this.patient = data.patient;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  fetchDoctor(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.index().subscribe(
      (data) => {
        this.doctorsAll = data.users;
        this.doctor = data.users;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.index().subscribe(
      (data) => {
        this.department = data.departments;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDoctorDepartments(id): any {
    const dep_id = this.doctor.filter((t) => t.id === Number(id))[0]
      .doctor_department_id;
    console.log('dep_id', dep_id);
    if (dep_id) {
      this.DialysisRequestForm.get('department_id').patchValue(dep_id);
    } else {
      this.DialysisRequestForm.get('department_id').patchValue('');
    }
  }
  fetchDeptDoctors(id): any {
    this.doctor = [];
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctor(id).subscribe(
      (data) => {
        this.doctor = data.users;
        this.ngxLoader.stop();
        this.DialysisRequestForm.get('doctor_id').patchValue('');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchUrgency(): any {
    this.urgency = [];
    this.employeeService.fetchConfiguration('urgency').subscribe(
      (data) => {
        this.urgency = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchAvailableSlots(): any {
    this.dialysisService
      .fetchAvailabaleSlots(this.DialysisRequestForm.value.suggested_date)
      .subscribe(
        (data) => {
          this.available_slots = data.available_slots;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
          this.available_slots = [];
        },
      );
  }

  createDialysisRequest(): any {
    this.submitted = true;
    this.dialysis_request = this.DialysisRequestForm.value;
    if (this.DialysisRequestForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.dialysisService
      .create(this.dialysis_request, this.patient.id)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success('Added successfully');
          this.submitted = false;
          // this.fetchAvailableSlots();
          this.backClicked();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  resetDialysisRequestForm(): any {
    this.DialysisRequestForm.reset();
    this.ngOnInit();
  }

  backClicked(): any {
    this._location.back();
  }

  get f(): any {
    return this.DialysisRequestForm.controls;
  }
}
