import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabParameterService } from '../../../services/lab-parameter.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-parameters-v2',
  templateUrl: './parameters-v2.component.html',
  styleUrls: ['./parameters-v2.component.css'],
})
export class ParametersV2Component implements OnInit {
  submitted = false;
  labParameters = [];
  reportType = '';

  page = 1;
  totalPages: any;
  perPage = 25;
  pageDataList: any;
  parameterCode = '';
  parameterName = '';
  screenName = '';
  isUpdateParameter = false;
  isUpdated: any;
  showParameterModalData = false;
  assessInvestigationID: any;
  returnObjectParameter: any;
  returnUpdate: any;
  isEditParameter = false;
  isAddParameterNew = false;
  modalHiden = true;
  indexOfLabParameter = 0;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private labParameterService: LabParameterService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    this.ngxLoader.stop();
    this.fetchAllLabParmeters();
  }

  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.fetchAllLabParmeters();
  }

  loadPage(event): any {
    this.fetchAllLabParmeters();
  }

  searchParameter(): any {
    this.page = 1;
    this.fetchAllLabParmeters();
  }

  fetchAllLabParmeters(): any {
    this.ngxLoader.start();
    this.pageDataList = [];
    this.labParameterService
      .fetch_all_parametersV2(
        this.page,
        this.perPage,
        this.parameterCode,
        this.parameterName,
      )
      .subscribe(
        (data) => {
          this.parameterCode = '';
          this.parameterName = '';
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          this.pageDataList = data.lab_assessments;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  parameterDetail(id): any {
    const url_string = decodeURIComponent('/lab/update-parameter?lab_id=' + id);
    this.router.navigateByUrl(url_string);
  }
  addLabParameter(): any {
    this.showParameterModalData = false;
    this.isUpdateParameter = false;
    this.isUpdated = '';
    $('#addMainParameter').modal('show');
    setTimeout(() => {
      this.showParameterModalData = true;
    }, 500);
  }
  editParameter(element, i): any {
    this.showParameterModalData = false;
    this.indexOfLabParameter = i;
    this.isUpdateParameter = true;
    this.isUpdated = element.id;
    // this.assessInvestigationID = this.savedLabParameters[i].assess_investigation_id;
    this.assessInvestigationID = '';
    $('#addMainParameter').modal('show');
    setTimeout(() => {
      this.showParameterModalData = true;
    }, 500);
  }
  nameEventHander($event: any) {
    if ($event === 'true') {
      // $('#addParameterModal').modal('show');
      if (this.isEditParameter === true) {
        console.log('returnObjectParameter update', this.returnObjectParameter);
        this.pageDataList[this.indexOfLabParameter] =
          this.returnObjectParameter;
        this.isEditParameter = false;
      }
      if (this.isAddParameterNew === true) {
        console.log('returnObjectParameter add', this.returnObjectParameter);
        this.pageDataList.unshift(this.returnObjectParameter);
        this.pageDataList.splice(this.pageDataList.length, 1);
        this.isAddParameterNew = false;
      }
      this.showParameterModalData = false;
    } else {
      this.showParameterModalData = true;
    }
  }
}
