import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskManagementService } from '../../../services/task-management.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteShareService } from '../../../services/route-share.service';
import {AuthService} from "../../../services/auth.service";
import {logWarnings} from "protractor/built/driverProviders";
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {
  project: FormGroup;
  subType: FormGroup;
  typeList: any;
  subTypeId: any;
  subTypeList: any;
  currentMedicalUnitId: any;
  showUpdateType = false;
  isAddType = false;
  isAddSubType = false;
  showUpdateSubType = false;
  typeId: '';
  screenName = '';
  medicalUnitsData = [];

  constructor(
    private fb: FormBuilder,
    private taksServices: TaskManagementService,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
    private authService: AuthService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  ngOnInit(): void {
    this.currentMedicalUnitId = localStorage.getItem('current_medical_unit_id');

    $('#addTypeModal').on('hide.bs.modal', () => {
      this.project.reset({
        name: '',
        ref_medical_unit: '',
        is_active: false,
      });
    });
    this.project = this.fb.group({
      name: ['', Validators.required],
      ref_medical_unit: ['', Validators.required],
      is_active: false,
    });
    this.getProjectList();
    this.medicalUnits();
  }
  addTags(): any {
    this.showUpdateType = false;
    $('#addTypeModal').modal('show');
    if (this.currentMedicalUnitId === '136' || this.currentMedicalUnitId === '217') {
      this.project.get('ref_medical_unit').patchValue('');
      this.project.get('ref_medical_unit').enable();
      setTimeout(() => {
        $('#medical_unit').selectpicker('refresh');
      }, 500);
    } else {
      this.project.get('ref_medical_unit').patchValue(this.currentMedicalUnitId);
      this.project.get('ref_medical_unit').disable();
      setTimeout(() => {
        $('#medical_unit').selectpicker('refresh');
      }, 500);
    }
  }
  addSubTypes(): any {
    this.showUpdateSubType = false;
    $('#addSubTypeModal').modal('show');
  }
  medicalUnits(): any {
    this.authService.getMedicalUnits().subscribe(
      (data) => {
        this.medicalUnitsData = data.medical_units;
        setTimeout(() => {
          $('#medical_unit').selectpicker();
          $('#medical_unit').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  getTagUpdate(id, index, data): any {
    this.typeId = id;
    this.showUpdateType = true;
    this.ngxLoader.start();
    this.project.patchValue({
      name: data.name,
      // ref_medical_unit: data.ref_medical_unit,
      is_active: data.is_active,
    });
    this.ngxLoader.stop();
    $('#addTypeModal').modal('show');
    if (this.currentMedicalUnitId === '136' || this.currentMedicalUnitId === '217') {
      this.project.patchValue({
        ref_medical_unit: data.ref_medical_unit,
      });
      this.project.get('ref_medical_unit').enable();
      setTimeout(() => {
        $('#medical_unit').selectpicker('refresh');
      }, 500);
    } else {
      this.project.get('ref_medical_unit').patchValue(this.currentMedicalUnitId);
      this.project.get('ref_medical_unit').disable();
      setTimeout(() => {
        $('#medical_unit').selectpicker('refresh');
      }, 500);
    }
    setTimeout(() => {
      $('#medical_unit').selectpicker();
      $('#medical_unit').selectpicker('refresh');
    }, 500);
  }
  closeModal(): any{
    $('#addTypeModal').modal('hide');
    this.project.patchValue({
      name: '',
      ref_medical_unit: '',
      is_active: false,
    });
    setTimeout(() => {
      $('#medical_unit').selectpicker();
      $('#medical_unit').selectpicker('refresh');
    }, 500);
  }

  submitType(): any {
    this.isAddType = true;
    if (this.project.invalid) {
      return;
    }

    if (this.showUpdateType === false) {
      this.taksServices.addProject(this.project.getRawValue()).subscribe(
        (data) => {
          this.isAddType = false;
          this.closeModal();
          this.ngxLoader.stop();
          this.getProjectList();
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
      $('#addTypeModal').modal('hide');
    } else {
      this.taksServices
        .updateProject(this.project.getRawValue(), this.typeId)
        .subscribe(
          (data) => {
            this.isAddType = false;
            this.closeModal();
            this.showUpdateType = false;
            this.getProjectList();
            this.ngxLoader.stop();
          },
          (err) => {
            console.log('err', err);
            this.ngxLoader.stop();
            toastr.error(err.error.error);
          },
        );
    }
  }

  getProjectList(): any {
    this.taksServices.getProject('').subscribe(
      (data) => {
        this.typeList = data;
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
