import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigurationService } from '../../../services/configuration.service';
// import {WorkerService} from '../../../services/worker.service';
import { RouteShareService } from '../../../services/route-share.service';
import { UserService } from '../../../services/user.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductCatalogService } from '../../../services/product-catalog.service';
import { ActivatedRoute } from '@angular/router';
import { StoreManagementService } from '../../../services/store-management.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-worker',
  templateUrl: './worker.component.html',
  styleUrls: ['./worker.component.css'],
})
export class WorkerComponent implements OnDestroy, OnInit {
  LabParameterForm: FormGroup;
  servicesKit: FormGroup;
  feedbackForm: FormGroup;
  list = [];
  isSubmit = false;
  productArrayList = [];
  stores: any;
  feedBackData = [];
  clientData: any;
  feedbackSuggestion: '';
  selectedClientId = '';
  store = '';
  lineItems = [];
  lineItem = '';
  products = '';
  productId: any;
  serviceID: any;
  serviceName: any;
  perPage = 25;
  totalPages: any;
  page = 1;
  perPageValue: any;
  depart: any;
  invoiceNo = '';
  search_query = 'default';
  crn = '';
  // list: any = [];
  endDate: any;
  chair: any;
  departmentSearchValue = '';
  name = '';
  status = -1;
  department = '';
  employeesOfDepart: any;
  technicalStaff = '';
  type: any;
  getDataForloan: any;
  settingData: any;
  screenName = '';
  employees = [];
  employeesList = [];
  workerData = [];
  removeServiceId: any;
  reason: any;
  chairDepartId: any;
  chairData: any;
  chairServiceId: any;
  technicalServiceId: any;
  technicalStaffData: any;
  nonTechnical: any;
  nonTechnicalServiceId: any;
  screenControls = [];
  checkIn: any;
  checkOut: any;
  checkInId: any;
  serviceIdAtKitRequest: any;
  checkOutId: any;
  allowRemoveService = false;
  productlist = [];
  // productArrayList = [];
  // productId: any;
  // serviceName: any;
  serviceIdForRecipe: any;
  serviceItemId: any;
  clientIdForRecipe: any;
  serviceData = [];
  totalCost: any;
  ItemCheckInOutTimeData: any;
  intervalId: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    // private configurationService: ConfigurationService,
    // private workerService: WorkerService,
    private routeSevice: RouteShareService,
    private userService: UserService,
    private productService: ProductCatalogService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private storeService: StoreManagementService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.LabParameterForm = this.fb.group({
      parametersArray: this.fb.array([this.productArray()]),
    });
    this.servicesKit = this.fb.group({
      serviceKit: this.fb.array([]),
    });

    this.feedbackForm = this.fb.group({
      feedback_suggestion: [''],
      client_id: [''],
      services: this.fb.array([this.feedbackServices()]),
    });
    this.serviceID = this.route.snapshot.queryParamMap.get('id');
    this.serviceName = this.route.snapshot.queryParamMap.get('name');
    this.searchFilter();
    this.getParentStoreList();
    this.getLineItem();
  }

  get controlFeedback(): any {
    return this.feedbackForm.get('services') as FormArray;
  }

  changeStatus(i): any {
    if ($('#is_check' + i).is(':checked')) {
      this.controlFeedback.at(i).get('is_check').setValue(true);
    } else {
      this.controlFeedback.at(i).get('is_check').setValue(false);
    }
  }

  changeStatusSatisfied(i): any {
    if ($('#is_satisfied' + i).is(':checked')) {
      this.controlFeedback.at(i).get('is_satisfied').setValue(true);
    } else {
      this.controlFeedback.at(i).get('is_satisfied').setValue(false);
    }
  }

  productArray(): any {
    return this.fb.group({
      id: [''],
      unit_required: [''],
      product_type: [''],
      product_name: [''],
      code: [''],
    });
  }

  feedbackServices(): any {
    return this.fb.group({
      name: [''],
      is_check: [''],
      is_satisfied: [''],
      service_id: [''],
      item_id: [''],
    });
  }

  get labParametersArray(): any {
    return this.LabParameterForm.get('parametersArray') as FormArray;
  }

  getParentStoreList(): any {
    this.storeService.getParentStoreList().subscribe(
      (data) => {
        this.stores = data;
        setTimeout(() => {
          $('#store').selectpicker('refresh');
          $('#store').selectpicker();
        }, 500);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  // goBack(): any{
  //   this.location.back();
  // }
  getProductsById(e): any {
    // this.storeService.getProductsByLineItemIdNew(e).subscribe(data => {
    this.list;
    // this.list = data.items;
    setTimeout(() => {
      $('#products').selectpicker('refresh');
      $('#products').selectpicker();
    }, 500);
    // }, err => {
    //   toastr.error(err.error.error);
    // });
  }

  getLineItem(): any {
    this.ngxLoader.start();
    this.storeService.getlineItems().subscribe(
      (data) => {
        this.lineItems = data;
        setTimeout(() => {
          $('.lineItem').selectpicker('refresh');
          $('.lineItem').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.workerListing(true);
  }

  loadPage(event): any {
    this.workerListing(true);
  }

  // getEmployeesOfDepartment(): any {
  //   if ( this.department === undefined || this.department === null || this.department === [] || this.department === ''){
  //     this.department = '';
  //   }
  //   if ( this.type === undefined || this.type === null || this.type === [] || this.type === ''){
  //     this.type = '';
  //   }
  //
  //   this.employeesOfDepart = [];
  //   setTimeout((e) => {
  //     $('#employeeDepartmentList').selectpicker('refresh');
  //   }, 200);
  //   this.configurationService.getEmployeesOfSpecificDepartment(this.department).subscribe(data => {
  //     this.employeesOfDepart = data;
  //     setTimeout((e) => {
  //       $('#employeeDepartmentList').selectpicker('refresh');
  //       $('#employeeDepartmentList').selectpicker();
  //     }, 500);
  //   }, err => {
  //     this.ngxLoader.stop();
  //     toastr.error(err.error.message);
  //   });
  // }
  // getloantype(): any {
  //   setTimeout((e) => {
  //     $('.leave-type').selectpicker('refresh');
  //   }, 500);
  //   this.loan.getLoanTypeActive().subscribe( data => {
  //     this.getDataForloan = data;
  //     setTimeout((e) => {
  //       $('.leave-type').selectpicker('refresh');
  //     }, 500);
  //     setTimeout((e) => {
  //       $('.leave-type').selectpicker();
  //     }, 500);
  //   }, err => {
  //     toastr.error(err.error.error);
  //   });
  // }
  getDepartments(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    // this.workerService.departmentServices().subscribe(data => {
    this.depart = '';
    // this.depart = data.departments;
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    setTimeout((e) => {
      $('.selectpicker').selectpicker();
    }, 500);
    // });
  }

  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updateClinicCheck();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateClinicCheck(): any {
    if (this.screenControls.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'allowremoveservice')
          .length > 0
      ) {
        this.allowRemoveService = this.screenControls.filter(
          (m) => m.code === 'allowremoveservice',
        )[0].is_show;
        if (!this.allowRemoveService) {
          $('#removeButton').hide();
        }
      }
    }
  }

  // selectPaginationLimit(event): any {
  //   this.perPage = event;
  //   if ( event === '' ) {
  //     this.perPage = this.totalPages;
  //     this.page = 1;
  //     this.perPageValue = 'All';
  //   } else {
  //     this.perPageValue = event;
  //   }
  //   this.getLoanReport();
  // }
  // getSettingsData(): any {
  //   this.hospitalService.getMedicalUnitDetails().subscribe( data => {
  //       this.settingData = data.medical_unit;
  //     },
  //     err => {
  //       toastr.error(err.error.error);
  //     });
  // }
  //
  // loadPage(event): any {
  //   this.getLoanReport();
  // }
  // getLoanReport(): any {
  //   if ( this.employeesOfSelectedDepartment === undefined || this.employeesOfSelectedDepartment === null || this.employeesOfSelectedDepartment === [] || this.employeesOfSelectedDepartment === ''){
  //     this.employeesOfSelectedDepartment = '';
  //   }
  //   this.ngxLoader.start();
  //   this.loan.getLoanReport($('.start_date').val(), $('.end_date').val(), this.department, this.employeesOfSelectedDepartment, this.status, this.type, this.page, this.perPage).subscribe( data => {
  //     this.list = data;
  //     this.totalPages = this.list.total_count;
  //     this.list = this.list.items;
  //     if ( !this.list.length ) {
  //       toastr.error('Record Not Found!');
  //     }
  //     this.ngxLoader.stop();
  //   }, err => {
  //     toastr.error(err.error.error);
  //     this.ngxLoader.stop();
  //   });
  // }
  // printReport(): any {
  //   window.print();
  // }
  // initializeTimepickers(): any {
  //   setTimeout((e) => {
  //     $('#avail_to').timepicker({
  //       minuteStep: 1,
  //       defaultTime: true,
  //     });
  //   }, 100);
  // }
  // initializeTimepickers2(): any {
  //   setTimeout((e) => {
  //     $('#avail_from').timepicker({
  //       format: 'HH:mm:ss',
  //       minuteStep: 1,
  //       defaultTime: true,
  //     });
  //   }, 100);
  // }
  workerListing(check): any {
    // this.workerData = [];
    if (check) {
      this.ngxLoader.start();
    }
    // this.workerService.workerListing(this.search_query, this.crn, this.invoiceNo, this.department, this.technicalStaff, this.status, this.page, this.perPage).subscribe(data => {
    this.workerData;
    // this.workerData = data.services;
    // if (data.meta) {
    //   this.totalPages = data.meta.total_count;
    // }
    if (check) {
      this.ngxLoader.stop();
    }
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  getChair(): any {
    // this.workerService.chairListing(this.chairDepartId).subscribe(data => {
    this.chairData = '';
    // this.chairData = data.events;
    setTimeout((e) => {
      $('#chair').selectpicker('refresh');
      $('#chair').selectpicker();
    }, 500);
    // }, err => {
    //   setTimeout((e) => {
    //     $('#chair').selectpicker('refresh');
    //   }, 500);
    //   this.ngxLoader.stop();
    //   // toastr.error(err.error.message);
    // });
  }

  openRemoveServiceModal(id): any {
    this.reason = '';
    this.removeServiceId = id;
    $('#RemoveServiceModal').modal('show');
  }

  closeRemoveServiceModal(): any {
    $('#RemoveServiceModal').modal('hide');
  }

  openChairModal(id, serviceId, chair_id): any {
    this.chair = chair_id;
    this.chairServiceId = serviceId;
    this.chairDepartId = id;
    this.getChair();
    $('#addChairModal').modal('show');
  }

  closeChairModal(): any {
    $('#addChairModal').modal('hide');
  }

  addChair(): any {
    this.ngxLoader.start();
    // this.workerService.addChair(this.chairServiceId, this.chairDepartId, this.chair).subscribe(data => {
    this.ngxLoader.stop();
    $('#addChairModal').modal('hide');
    this.workerListing(false);
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
    this.ngxLoader.stop();
  }

  submitReason(): any {
    if (this.reason === '') {
      $('#reason').css('border', 'danger');
      toastr.error('Reason is Mandatory');
      return;
    }
    this.ngxLoader.start();
    // this.workerService.submitReason(this.removeServiceId, this.reason).subscribe(data => {
    this.ngxLoader.stop();
    toastr.success('chair add Successfully');
    $('#RemoveServiceModal').modal('hide');
    this.workerListing(false);
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
    this.ngxLoader.stop();
  }

  formatString(str: string): string {
    return str.replace(/,/g, ',<br>');
    // const arr = str.split(',');
    // return arr.join(',<br>');
  }

  addEmployee(): any {
    this.ngxLoader.start();
    // this.workerService.addEmployee(this.technicalServiceId, this.technicalStaffData).subscribe(data => {
    this.ngxLoader.stop();
    toastr.success('Employee add Successfully');
    $('#technicalModal').modal('hide');
    this.workerListing(false);
    // this.getTechnicalNonTechnicalStaff();
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  getItemCheckInOutTime(): any {
    // this.workerService.getItemCheckInOutTime().subscribe(data => {
    this.ItemCheckInOutTimeData;
    // this.ItemCheckInOutTimeData = data.services;
    if (this.ItemCheckInOutTimeData) {
      for (let i = 0; i < this.ItemCheckInOutTimeData.length; i++) {
        this.appendModal(i);
        // $('.modal-content').css('box-shadow', '4px solid red');
        $('#notificationModal' + i).modal('show');
        $('.modal-backdrop.show').css('opacity', '-0.2');
        $('.modal-content').css('box-shadow', 'inset 0 0 5px #999');
        i++;
      }
    }
    // }, err => {
    //   toastr.error(err.error.message);
    // });
  }

  appendModal(index: number): any {
    $('body').append(
      '<div class="modal fade" id="notificationModal' +
        index +
        '" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
        '<div class="modal-dialog modal-md" role="document">' +
        '<div class="modal-content">' +
        '<div class="modal-header pt-2 pb-2">' +
        '<h5>Time Notification</h5>' +
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        '</div>' +
        '<div class="modal-body">' +
        '<div>' +
        '<h5>Service time out, please check out the client</h5>' +
        '</div>' +
        '</div>' +
        '<div class="modal-footer p-3">' +
        '<button type="button" class="btn btn-sm btn-primary" data-dismiss="modal">Close</button>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>',
    );
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.getItemCheckInOutTime();
      // code to be executed repeatedly
    }, 600000); // execute every 1000ms (1 second)
    // }, 120000); // execute every 1000ms (1 second)
    setTimeout(() => {
      this.getItemCheckInOutTime();
    }, 2000);
    this.getScreenControls();
    this.getTechnicalStaffForFilter();
    this.workerListing(true);
    this.getDepartments();
    this.searchFilter();
    this.perPageValue = 25;
    // this.getDepartments();
    // // this.getLoanReport();
    // this.getloantype();
    // this.getSettingsData();
  }

  openWorkerModal(): any {
    $('#workerModal').modal('show');
  }

  closeWorkerModal(): any {
    $('#nonTechnicalModal').modal('hide');
  }

  openCheckInModal(id, checkIn): any {
    this.checkIn = checkIn;
    this.checkInId = id;
    $('#checkInModal').modal('show');
  }

  closeCheckInModal(): any {
    $('#checkInModal').modal('hide');
  }

  openCheckOutModal(id, checkOut, checkIn): any {
    this.checkOut = checkOut;
    this.checkIn = checkIn;
    this.checkOutId = id;
    $('#checkOutModal').modal('show');
  }

  submitCheckOut(): any {
    if (this.checkOut < this.checkIn) {
      toastr.error('Check Out time should not be less then Check In time');
      return;
    }
    this.ngxLoader.start();
    // this.workerService.submitCheckOut(this.checkOut, this.checkOutId).subscribe(data => {
    this.ngxLoader.stop();
    $('#checkOutModal').modal('hide');
    this.workerListing(false);
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  closeCheckOutModal(): any {
    $('#checkOutModal').modal('hide');
  }

  closeNotificationModal(): any {
    $('#notificationModal').modal('hide');
  }

  submitCheckIn(): any {
    this.ngxLoader.start();
    // this.workerService.submitCheckIn(this.checkIn, this.checkInId).subscribe(data => {
    this.ngxLoader.stop();
    $('#checkInModal').modal('hide');
    this.workerListing(false);
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  submitKitRequestBtn(id): any {
    this.serviceIdAtKitRequest = id;
    this.ngxLoader.start();
    // this.workerService.submitKitRequest(this.serviceIdAtKitRequest).subscribe(data => {
    this.ngxLoader.stop();
    $('#checkInModal').modal('hide');
    this.workerListing(false);
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  getTechnicalStaffForFilter(): any {
    // this.employees = [];
    // setTimeout((e) => {
    //   $('#employeeTechnical').selectpicker('refresh');
    // }, 200);
    // this.workerService.getTechnicalStaffForFilter().subscribe(data => {
    this.employeesList;
    // this.employeesList = data.employees;
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
      $('#employeeDepartmentList').selectpicker();
    }, 500);
    // }, err => {
    //   setTimeout((e) => {
    //     $('#employeeDepartmentList').selectpicker('refresh');
    //   }, 500);
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  getTechnicalNonTechnicalStaff(technical_filter, id): any {
    // this.employees = [];
    // setTimeout((e) => {
    //   $('#employeeTechnical').selectpicker('refresh');
    // }, 200);
    // this.workerService.getTechnicalNonTechnicalStaff(technical_filter, id).subscribe(data => {
    this.employees;
    // this.employees = data.employees;
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
      $('#employeeDepartmentList').selectpicker();
      $('#employeeTechnical').selectpicker('refresh');
      $('#employeeTechnical').selectpicker();
      $('#employee').selectpicker('refresh');
      $('#employee').selectpicker();
    }, 500);
    // }, err => {
    //   setTimeout((e) => {
    //     $('#employeeTechnical').selectpicker('refresh');
    //   }, 500);
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  openTechnicalModal(id, techId): any {
    this.technicalStaffData = techId;
    this.technicalServiceId = id;
    this.getTechnicalNonTechnicalStaff('Technical', id);
    setTimeout((e) => {
      $('#employeeTechnical').selectpicker('refresh');
      $('#employeeTechnical').selectpicker();
    }, 500);
    $('#technicalModal').modal('show');
  }

  closeTechnicalModal(): any {
    $('#technicalModal').modal('hide');
  }

  openNonTechnicalModal(id, nonTechId): any {
    this.nonTechnical = nonTechId;
    this.nonTechnicalServiceId = id;
    this.getTechnicalNonTechnicalStaff('Non-Technical', id);
    setTimeout((e) => {
      $('#employee').selectpicker('refresh');
      $('#employee').selectpicker();
    }, 500);
    $('#nonTechnicalModal').modal('show');
  }

  closeNonTechnicalModal(): any {
    $('#nonTechnicalModal').modal('hide');
  }

  addNonTechnical(): any {
    this.ngxLoader.start();
    // this.workerService.addNonTechnical(this.nonTechnicalServiceId, this.nonTechnical).subscribe(data => {
    $('#nonTechnicalModal').modal('hide');
    this.workerListing(false);
    // this.getTechnicalNonTechnicalStaff();
    this.ngxLoader.stop();
    // }, err => {
    //   this.ngxLoader.stop();
    // });
    this.ngxLoader.stop();
  }

  serviceKit(id, name): any {
    this.serviceName = name;
    this.searchFilter();
    $('#kitModal').modal('show');
    this.productArrayList = [];
  }

  getProductId(id): any {
    this.productId = id;
  }

  // addProduct(): any{
  //   const getSingleProduct = this.productlist.find(t => t.id === Number(this.productId));
  //   this.productArrayList.push(getSingleProduct);
  // }
  addProduct(): any {
    const getSingleProduct = this.list.find(
      (t) => t.id === Number(this.productId),
    );
    this.controlDose.push(this.controlDoseArray(getSingleProduct));
  }

  controlDoseArray(data): any {
    return this.fb.group({
      ingredient_id: [''],
      id: [data.id],
      code: [data.code],
      name: [data.name],
      manufacturer_name: [data.manufacturer_name],
      line_item: [data.line_item],
      line_item_id: [data.line_item_id],
      unit_quantity: [data.unit_quantity],
      // unit_req: [data.unit_quantity],
      uom: [data.uom],
      purchase_price: [data.purchase_price],
      total_purchase_price: [0],
      unit_req: [data.unit_req || 1],
    });
  }

  get controlDose(): any {
    return this.servicesKit.get('serviceKit') as FormArray;
  }

  searchFilter(): any {
    this.ngxLoader.start();
    this.productlist = [];
    this.productService.getProductListing(1, 25, '').subscribe(
      (data) => {
        this.productlist = data.items;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  removeProductData(i): any {
    this.controlDose.removeAt(i);
  }

  // getServiceKit(id): any{
  //   this.ngxLoader.start();
  //   this.productService.getServiceKit(id).subscribe(data => {
  //     this.serviceData = data.service_kit;
  //     if (this.serviceData){
  //       while (this.controlDose.length) {
  //         this.controlDose.removeAt(0);
  //       }
  //       for (let i = 0; i < this.serviceData.length; i++){
  //         this.controlDose.push(this.getKitData(this.serviceData[i]));
  //       }
  //     }
  //     this.ngxLoader.stop();
  //   }, err => {
  //     this.ngxLoader.stop();
  //     toastr.error(err.error.message);
  //   });
  // }
  productCost(value): any {
    for (let i = 0; i < this.controlDose.length; i++) {
      this.totalCost =
        this.controlDose.at(i).get('purchase_price').value * value;
    }
  }

  // getKitData(data): any{
  //   return this.fb.group({
  //     ingredient_id: [data.ingredient_id],
  //     id: [data.id],
  //     code: [data.code],
  //     name: [data.name],
  //     manufacturer_name: [data.manufacturer_name],
  //     line_item: [data.line_item],
  //     line_item_id: [data.line_item_id],
  //     unit_quantity: [data.unit_quantity],
  //     // unit_req: [data.unit_quantity],
  //     uom: [data.uom],
  //     purchase_price: [data.purchase_price],
  //     total_purchase_price: [0],
  //     unit_req: [data.unit_req || 1],
  //   });
  // }

  openRecipyModal(id, clientID, itemId): any {
    this.serviceIdForRecipe = id;
    this.serviceItemId = itemId;
    this.clientIdForRecipe = clientID;
    this.store = '';
    this.lineItem = '';
    this.products = '';
    this.controlDose.controls = [];
    // this.getServiceKit(id);
    $('#recipyModal').modal('show');
    setTimeout(() => {
      $('#store').selectpicker('refresh');
      $('#store').selectpicker();
      $('#products').selectpicker('refresh');
      $('#products').selectpicker();
      $('.lineItem').selectpicker('refresh');
      $('.lineItem').selectpicker();
    }, 500);
  }

  openFeedBackModal(id): any {
    // $('#feedbackModal').modal('show');
    this.selectedClientId = id;
    while (this.controlFeedback.length) {
      this.controlFeedback.removeAt(0);
    }
    // return;
    this.ngxLoader.start();
    // this.productService.getfeedBack(id).subscribe(data => {
    // $('#feedbackModal').modal('show');
    this.clientData;
    // this.clientData = data?.client?.client;
    this.feedBackData;
    // this.feedBackData = data?.services;
    // for (let i = 0; i < this.variantData.length; i++){
    //   this.controlInvoicing.push(this.invoiceData(this.variantData[i]));
    // }
    for (let i = 0; i < this.feedBackData.length; i++) {
      this.controlFeedback.push(this.controlFeedData(this.feedBackData[i]));
    }
    $('#feedbackModal').modal('show');
    this.ngxLoader.stop();
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }
  controlFeedData(data): any {
    // for (let i = 0; i < data.length; i++) {
    return this.fb.group({
      service_id: [data.service?.id],
      item_id: [data.id],
      name: [data?.service?.name],
      is_check: [data.is_check],
      is_satisfied: [data.is_satisfied],
    });
    // }
  }

  submitFeedbackForm(): any {
    let count = 0;
    for (let i = 0; i < this.feedbackForm.value.services.length; i++) {
      if (this.feedbackForm.value.services[i].is_check === true) {
        count++;
      }
    }
    if (count < 1) {
      toastr.error('Select atleast 1 Service');
      return;
    }
    count = 0;
    this.feedbackForm.get('client_id').setValue(this.selectedClientId);
    this.isSubmit = true;
    if (this.feedbackForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    // this.productService.submitFeedBack(this.feedbackForm.value).subscribe(data => {
    toastr.success('Feedback Submitted');
    this.ngxLoader.stop();
    $('#feedbackModal').modal('hide');
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
  }

  submitNewKit(): any {
    if (this.servicesKit.value.serviceKit.length === 0) {
      toastr.error('Add minimum 1 product');
      return;
    } else {
      this.ngxLoader.start();
      // this.productService.submitNewKit(this.servicesKit.value.serviceKit, this.serviceIdForRecipe, this.clientIdForRecipe, this.serviceItemId).subscribe(data => {
      this.ngxLoader.stop();
      $('#recipyModal').modal('hide');
      toastr.success('Added Successfully');
      // }, err => {
      //   this.ngxLoader.stop();
      //   toastr.error(err.error.message);
      // });
    }
  }
}
