import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { ServiceService } from '../../../services/service.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { RadiologyService } from '../../../services/radiology.service';
import { PdfServicesService } from '../../../services/pdf-services.service';
import { PatientService } from '../../../services/patient.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-log-report-v2',
  templateUrl: './log-report-v2.component.html',
  styleUrls: ['./log-report-v2.component.css'],
})
export class LogReportV2Component implements OnInit {
  currentEnvironment: string;
  radiologyServicesForm: FormGroup;
  page = 1;
  totalPages: any;
  perPage = 25;
  radiologyServices = [];
  subDepartments = [];
  pageDataList = [];
  dataListingCSV = [];
  settingData: any;
  services = [];

  constructor(
    private formBuilder: FormBuilder,
    private departmentsbyhospitalService: DepartmentsbyhospitalService,
    private ngxLoader: NgxUiLoaderService,
    private ref: ChangeDetectorRef,
    private radiologyService: RadiologyService,
    private pdfService: PdfServicesService,
    private patientService: PatientService,
  ) {}

  ngOnInit(): void {
    // this.currentEnvironment = environment.rorUrl;
    this.radiologyServicesForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_mrn: ['', Validators.required],
      department_id: [''],
      service_id: [''],
      report_type: [''],
    });
    setTimeout(() => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY h:mm a',
        autoClose: true,
      });
      const previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY h:mm a',
      });
      const currentTime = moment().format('DD-MM-YYYY h:mm a');
      $('#toDate').val(currentTime);
      // this.searchInvoices();
    }, 1000);
    this.getSettingsData();
    this.getRadiologySubDepartments();
    this.getRadiologyServices();
    $('#serviceiId').selectpicker();
    $('#reportType').selectpicker();
  }

  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getRadiologyServices(): any {
    this.services = [];
    this.radiologyService.getRadiologyServicesv2('Radiology').subscribe(
      (data) => {
        this.services = data.services;
        setTimeout(() => {
          $('#testId').selectpicker('refresh');
        }, 200);
        // setTimeout(() => {
        //   $('#testId').selectpicker();
        // }, 500);
      },
      (err) => {},
    );
  }

  getRadiologySubDepartments(): any {
    this.subDepartments = [];
    this.radiologyServicesForm.patchValue({
      department_id: '',
    });
    this.radiologyService.radiologySubDeptv2().subscribe(
      (data) => {
        this.subDepartments = data.departments;
        setTimeout(() => {
          $('#department').selectpicker('refresh');
        }, 200);
        // setTimeout(() => {
        //   $('#department').selectpicker();
        // }, 500);
      },
      (err) => {},
    );
  }
  fetchServicesByDepartment(id): any {
    this.radiologyServices = [];
    this.ngxLoader.start();
    this.radiologyService.fetchRadiolofyServicesName(id).subscribe(
      (data) => {
        this.radiologyServices = data.services;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#serviceiId').selectpicker('refresh');
        }, 300);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }
  searchInvoices(): any {
    this.radiologyServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }

  searchFilter(): any {
    this.ngxLoader.start();
    this.radiologyService
      .fetchRadiologyLogsv2(
        this.page,
        this.perPage,
        this.radiologyServicesForm.value,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.radiology) {
            this.pageDataList = data.radiology;
          } else {
            this.pageDataList = [];
          }
          this.ref.markForCheck();
          this.getListDataCSV();
          this.getCSV();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getListDataCSV(): any {
    this.dataListingCSV = [];
    for (let i = 0; i < this.pageDataList.length; i++) {
      let objCSV = {
        'Sr No.': i + 1,
        'Accession No.': this.pageDataList[i].accession_number,
        'Test Name': this.pageDataList[i].test_name.replace(
          /[^a-zA-Z0-9]/g,
          ' ',
        ),
        'Patient MRN': this.pageDataList[i].patient_data
          ? this.pageDataList[i].patient_data.patient_mrn
          : '',
        'Patient Name': this.pageDataList[i].patient_data
          ? this.pageDataList[i].patient_data.patient_name
          : '',
        Department: this.pageDataList[i].department_title.replace(
          /[^a-zA-Z0-9]/g,
          ' ',
        ),
        'Patient Category': this.pageDataList[i].category,

        'Created by': this.pageDataList[i].created_data
          ? this.pageDataList[i].created_data.created_by
          : '',
        'Created at': this.pageDataList[i].created_data
          ? moment(this.pageDataList[i].created_data.created_at).format(
              'DD-MM-yyyy h:mm a',
            )
          : '',

        'Delivered by': this.pageDataList[i].delivered_data
          ? this.pageDataList[i].delivered_data.delivered_by
          : '',
        'Delivered at': this.pageDataList[i].delivered_data
          ? moment(this.pageDataList[i].delivered_data.delivered_date).format(
              'DD-MM-yyyy h:mm a',
            )
          : '',

        'Acknowledge by': this.pageDataList[i].acknowledge_data
          ? this.pageDataList[i].acknowledge_data.acknowledged_by
          : '',
        'Acknowledge at': this.pageDataList[i].acknowledge_data
          ? moment(
              this.pageDataList[i].acknowledge_data.acknowledged_date,
            ).format('DD-MM-yyyy h:mm a')
          : '',

        'Final Sign by': this.pageDataList[i].final_sign_data
          ? this.pageDataList[i].final_sign_data?.final_signed_by
          : '',
        'Final Sign at': this.pageDataList[i].final_sign_data
          ? moment(
              this.pageDataList[i].final_sign_data?.final_sign_date,
            ).format('DD-MM-yyyy h:mm a')
          : '',
      };
      this.ref.markForCheck();
      this.dataListingCSV.push(objCSV);
    }
  }
  getCSV(): any {
    const array = [Object.keys(this.dataListingCSV[0])].concat(
      this.dataListingCSV,
    );
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
  }

  downloadCSV(): any {
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURI(this.getCSV());
    hiddenElement.target = '_blank';
    hiddenElement.download = 'RadiologyLogReport.csv';
    hiddenElement.click();
  }

  printReport(): any {
    window.print();
  }
}
