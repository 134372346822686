import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { RouteShareService } from '../../../services/route-share.service';
// import {environment} from '../../../../environments/environment.prod';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-lab-reports-reprint',
  templateUrl: './lab-reports-reprint.component.html',
  styleUrls: ['./lab-reports-reprint.component.css'],
})
export class LabReportsReprintComponent implements OnInit {
  current_environment: string;
  labServicesForm: FormGroup;
  page = 1;
  totalPages: any;
  perPage = 25;
  departments = [];
  subDepartments = [];
  pageDataList = [];
  filterType = 'All';

  reportDeliveredSubmited = false;
  rejectedSampleId = [];
  rejectedSampleReason = '';
  activePatient = '';
  selectedTestArray = [];
  selectedArray = [];
  showHeader = true;
  showFooter = true;
  screenName = '';
  constructor(
    private formBuilder: FormBuilder,
    private departmentsbyhospitalService: DepartmentsbyhospitalService,
    private ngxLoader: NgxUiLoaderService,
    private labTestService: LabTestService,
    private ref: ChangeDetectorRef,
    private modalService: NgbModal,
    private labconfigurationService: LabconfigurationService,
    private router: Router,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    this.current_environment = environment.rorUrl;
    this.labServicesForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_id: ['', Validators.required],
      department_id: [''],
      parent_department_id: [''],
      sub_department: [''],
      type: [''],
      lab_id: [''],
    });
    this.getDepartment();
    setTimeout(() => {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY h:mm a',
        autoClose: true,
      });
      let previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY h:mm a',
      });
      let current_time = moment().format('DD-MM-YYYY h:mm a');
      $('#toDate').val(current_time);
      this.searchInvoices();
    }, 1000);
  }
  getDepartment(): any {
    this.departments = [];
    this.subDepartments = [];
    this.departmentsbyhospitalService.index().subscribe(
      (data) => {
        this.departments = data.departments;
      },
      (err) => {},
    );
  }
  getSubDepartment(value): any {
    this.subDepartments = [];
    this.labServicesForm.patchValue({
      department_id: '',
    });
    if (value !== '') {
      this.ngxLoader.start();
      this.departmentsbyhospitalService.parent_dept(value).subscribe(
        (data) => {
          this.subDepartments = data.departments;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
    }
  }

  searchInvoices(): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: '',
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }

  filtersInvoices(filter): any {
    this.labServicesForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: filter,
    });
    if (filter === '') {
      this.filterType = 'All';
    }
    if (filter === 'opd') {
      this.filterType = 'OPD';
    }
    if (filter === 'ipd') {
      this.filterType = 'IPD';
    }
    if (filter === 'urgent') {
      this.filterType = 'Urgent';
    }
    if (filter === 'returned') {
      this.filterType = 'Returned';
    }
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.labTestService
      .fetchDeliveredLabReportsTest(
        this.page,
        this.perPage,
        this.labServicesForm.value,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.orders) {
            this.pageDataList = data.orders;
          } else {
            this.pageDataList = [];
          }
          this.ref.markForCheck();
          this.resetActivePatient();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }

  printReport(id): any {
    window.open(
      this.current_environment +
        '/v4/orders/lab_order_report.pdf?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
    );
  }
  printConfirmModal(): any {
    $('#printConfirmation').modal('show');
    this.showHeader = true;
    this.showFooter = true;
  }
  printReportByGroup(): any {
    $('#printConfirmation').modal('hide');
    this.selectedArray = [];
    for (let i = 0; i < this.selectedTestArray.length; i++) {
      if (this.selectedArray.length === 0) {
        let obj = {
          name: this.selectedTestArray[i].group_ids,
          data: [this.selectedTestArray[i].id],
        };
        this.selectedArray.push(obj);
      } else {
        this.setInnerGroupData(i);
      }
    }
    for (var z = 0; z < this.selectedArray.length; z++) {
      window.open(
        this.current_environment +
          '/v4/orders/lab_order_report.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          this.selectedArray[z].data +
          '&show_header=' +
          this.showHeader +
          '&show_footer=' +
          this.showFooter,
      );
    }
  }
  setInnerGroupData(i): any {
    var ind = -1;
    for (let j = 0; j < this.selectedArray.length; j++) {
      if (this.selectedArray[j].name === this.selectedTestArray[i].group_ids) {
        ind = j;
      }
    }
    if (ind === -1) {
      let obj = {
        name: this.selectedTestArray[i].group_ids,
        data: [this.selectedTestArray[i].id],
      };
      this.selectedArray.push(obj);
    } else {
      this.selectedArray[ind].data.push(this.selectedTestArray[i].id);
    }
  }
  selectLabTest(data, events): any {
    this.activePatient = data.patient_mrn;
    if (events.target.checked === true) {
      this.selectedTestArray.push(data);
    } else {
      const ind = this.selectedTestArray
        .map(function (img) {
          return img.id;
        })
        .indexOf(data.id);
      this.selectedTestArray.splice(ind, 1);
    }
    if (this.selectedTestArray.length === 0) {
      this.activePatient = '';
    }
  }
  resetActivePatient(): any {
    this.activePatient = '';
    this.selectedTestArray = [];
    $('.printCheckbox').prop('checked', false);
    this.ref.detectChanges();
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
