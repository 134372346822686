import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { LabReportsComponent } from './lab-reports/lab-reports.component';

import { LabTrackingReportComponent } from './reports/lab-tracking-report/lab-tracking-report.component';
import { PathologistRemarksComponent } from './pathologist-remarks/pathologist-remarks.component';
import { LinkLabParameterComponent } from './link-lab-parameter/link-lab-parameter.component';
import { LabReportsVerifyComponent } from './lab-reports-verify/lab-reports-verify.component';
import { LabReportsUpdateComponent } from './lab-reports-update/lab-reports-update.component';
import { LabReportsReprintComponent } from './lab-reports-reprint/lab-reports-reprint.component';
import { AuthorizationGuard } from '../../services/authorization.guard';
import { CultureConfigurationComponent } from './culture-configuration/culture-configuration.component';
import { PdfReportComponent } from './PDF-Report/pdf-report/pdf-report.component';
import { LabProcessingComponent } from './lab-processing/lab-processing.component';
import { LabControlsV2Component } from './lab-controls-v2/lab-controls-v2.component';
import { LabTestV2Component } from './lab-test-v2/lab-test-v2.component';
import { LabReportPdfComponent } from './reports/lab-report-pdf/lab-report-pdf.component';
import { ShareParameterComponent } from './share-parameter/share-parameter.component';
import { ParametersV2Component } from './parameters-v2/parameters-v2.component';
import { ConfigurationV2Component } from './configuration-v2/configuration-v2.component';
import { CultureConfigurationV2Component } from './culture-configuration-v2/culture-configuration-v2.component';
import { PcrConfigurationComponent } from './pcr-configuration/pcr-configuration.component';
import { LabTrackingReportV2Component } from './reports/lab-tracking-report-v2/lab-tracking-report-v2.component';
const routes: Routes = [
  {
    path: 'result-entry',
    component: LabReportsComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'result-verify',
    component: LabReportsVerifyComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'update-verified-result',
    component: LabReportsUpdateComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'reprint',
    component: LabReportsReprintComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'test-v2',
    component: LabTestV2Component,
    canActivate: [AuthorizationGuard],
  },
  { path: 'link-lab-parameter', component: LinkLabParameterComponent },

  { path: 'pathologist-remarks', component: PathologistRemarksComponent },

  { path: 'reports/tracking', component: LabTrackingReportComponent },
  { path: 'reports/tracking-v2', component: LabTrackingReportV2Component },

  {
    path: 'parameters-v2',
    component: ParametersV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'configuration-v2',
    component: ConfigurationV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'culture-configuration',
    component: CultureConfigurationComponent,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'culture-configuration-v2',
    component: CultureConfigurationV2Component,
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'controls-v2',
    component: LabControlsV2Component,
    canActivate: [AuthorizationGuard],
  },
  { path: 'pdf-report', component: PdfReportComponent },
  {
    path: 'lab-processing',
    component: LabProcessingComponent,
    canActivate: [AuthorizationGuard],
  },
  { path: 'lab-report-pdf', component: LabReportPdfComponent },
  { path: 'update-parameter-details', component: ShareParameterComponent },
  { path: 'pcr-config', component: PcrConfigurationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LabRoutingModule {}
