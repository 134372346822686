import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { StoreManagementService } from '../../../services/store-management.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShareDataService } from '../../../services/share-data.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-service-costing-detail',
  templateUrl: './service-costing-detail.component.html',
  styleUrls: ['./service-costing-detail.component.css'],
})
export class ServiceCostingDetailComponent implements OnInit {
  @Output() updateCostingData: EventEmitter<any> = new EventEmitter<any>();
  @Input() labCostingData: any;
  costDetail: FormGroup;
  productSearch = '';
  showData = false;
  showCostingDetail = false;
  productSearchList = [];
  products = [];
  searchValue: any;
  typingTimer: any;
  idOfSingleProduct: any;
  duplicationArrayCheck = false;
  selectedProductItem: any;
  activeDropDownIndex = 0;
  itemsCost = 0;
  totalCost = 0;
  totalPrice = 0;
  selectedProduct = '';
  selectedProductsArray = [];

  constructor(
    private storeService: StoreManagementService,
    private sharedService: ShareDataService,
    private fb: FormBuilder,
  ) {
    this.costDetail = this.fb.group({
      cost_details: this.fb.array([]),
      over_head_cost: [''],
      margin: [''],
      item_cost: [''],
      total_cost: [''],
      total_price: [''],
      is_service_price: [false],
    });
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): any {
    if (event.key === 'ArrowUp') {
      this.moveSelection(-1);
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      this.moveSelection(1);
      event.preventDefault();
    } else if (event.key === 'Enter' && this.showData && this.selectedProduct) {
      const productIndex = this.activeDropDownIndex;
      this.appendRow(this.productSearchList[productIndex]);
    }
  }
  showHIdeCostingDetail(): any {
    this.showCostingDetail = !this.showCostingDetail;
  }
  moveSelection(offset: number): any {
    if (this.showData && this.selectedProduct) {
      const newIndex = this.activeDropDownIndex + offset;
      if (newIndex >= 0 && newIndex < this.productSearchList.length) {
        this.activeDropDownIndex = newIndex;
      }
      this.scrollToRow();
    }
  }
  scrollToRow(): void {
    const innerScrollContainer = document.getElementById(
      'innerScrollContainer',
    );
    const targetRow = document.getElementById(
      'productID' + this.activeDropDownIndex,
    );

    if (innerScrollContainer && targetRow) {
      const scrollOffset = targetRow.offsetTop - innerScrollContainer.offsetTop;
      innerScrollContainer.scrollTop = scrollOffset;
    }
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  removeSearchString(): any {
    this.selectedProduct = '';
    this.productSearchList = [];
    this.searchValue = 0;
    this.showData = false;
    if (this.searchValue > 0) {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'block',
      );
    } else {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'none',
      );
    }
  }
  setTimeToCallProduct(search_value): any {
    clearTimeout(this.typingTimer); // Clear the previous timer
    this.typingTimer = setTimeout(() => {
      this.searchProduct(search_value); // Call your search function here
    }, 1000);
  }
  searchProduct(search_value): any {
    if (this.selectedProduct === '' || this.selectedProduct === null) {
      // this.productSearchValue = [''];
      this.activeDropDownIndex = 0;
      this.showData = false;
    }
    this.searchValue = search_value.length;
    if (this.searchValue > 0) {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'block',
      );
    } else {
      $('.quick-search .quick-search-form .quick-search-close').css(
        'display',
        'none',
      );
      this.selectedProduct = '';
    }
    if (this.searchValue > 0) {
      // this.eventOfSearchBar = event;
      this.storeService
        .getProductsForLabTest(search_value)
        .subscribe((data) => {
          if (this.selectedProduct === search_value) {
            this.productSearchList = data;
            this.products = data;
            this.showData = true;
            this.activeDropDownIndex = 0;
          }
        });
    }
  }
  appendRow(value): any {
    this.removeSearchString();
    this.idOfSingleProduct = value.id;
    this.addProductToArray();
  }
  addProductToArray(): any {
    this.duplicationArrayCheck = false;
    if (this.costDetails.length > 0) {
      this.duplicationArrayCheck = this.costDetails.value.find((t) => {
        if (t?.id === Number(this.idOfSingleProduct)) {
          toastr.error('Product Already Exists');
          return true;
        }
      });
    }
    if (this.duplicationArrayCheck) {
      return;
    }
    this.products.find((t) => {
      if (t?.id === Number(this.idOfSingleProduct)) {
        this.costDetails.push(this.costDetailsArray(t));
        // this.duplicateSelectedProductsArray.push(t);
      }
    });
    this.calculateTotal();
  }
  costDetailsArray(data): any {
    return this.fb.group({
      id: data.id,
      code: data.code,
      name: data.name,
      unit_quantity: data.unit_quantity === 0 ? 1 : data.unit_quantity,
      purchase_price: data.purchase_price,
      required_qty: [data.required_qty || 1],
      uom: data.uom,
      cost: [0],
    });
  }
  calculateTotal(): any {
    this.itemsCost = 0;
    for (let j = 0; j < this.costDetails.value.length; j++) {
      this.costDetails
        .at(j)
        .get('cost')
        .setValue(
          (
            (this.costDetails.at(j).get('purchase_price').value *
              Number(this.costDetails.at(j).get('required_qty').value)) /
            this.costDetails.at(j).get('unit_quantity').value
          ).toFixed(2),
        );
      this.itemsCost += parseFloat(this.costDetails.value[j].cost); // Use parseFloat to convert string to number
    }
    const totalCost =
      Number(this.itemsCost) +
        Number(this.costDetail.get('over_head_cost').value) || 0;
    this.totalCost = Number(totalCost.toFixed(2));
    const totalPrice =
      Number(this.totalCost) *
        (Number(this.costDetail.get('margin').value) / 100) || 0;
    this.totalPrice = Number((this.totalCost + totalPrice).toFixed(2));
    this.costDetail.get('item_cost').setValue(this.itemsCost || 0);
    this.costDetail.get('total_cost').setValue(this.totalCost || 0);
    this.costDetail.get('total_price').setValue(this.totalPrice || 0);
    this.updateCostingData.emit(this.costDetail.value);
  }
  get costDetails(): any {
    return this.costDetail.get('cost_details') as FormArray;
  }
  removeProduct(i): any {
    this.costDetails.removeAt(i);
    this.calculateTotal();
  }
  calculateCost(i): any {
    this.itemsCost = 0;
    // if (
    //   this.costDetails.at(i).get('required_qty').value >
    //   this.costDetails.at(i).get('unit_quantity').value
    // ) {
    //   this.costDetails
    //     .at(i)
    //     .get('required_qty')
    //     .setValue(this.costDetails.at(i).get('unit_quantity').value);
    // }
    this.costDetails
      .at(i)
      .get('cost')
      .setValue(
        (
          (this.costDetails.at(i).get('purchase_price').value *
            this.costDetails.at(i).get('required_qty').value) /
          this.costDetails.at(i).get('unit_quantity').value
        ).toFixed(2),
      );
    this.calculateTotal();
  }
  calTotalPrice(): any {
    if (Number(this.costDetail.get('margin').value) > 100) {
      this.costDetail.get('margin').setValue(100);
    }
    this.calculateTotal();
  }
  addToServicePrice(): any {
    this.updateCostingData.emit(this.costDetail.value);
  }
  clearCostDetail(): any {
    while (this.costDetails.length) {
      this.costDetails.removeAt(0);
    }
    this.costDetail.get('over_head_cost').setValue('');
    this.costDetail.get('item_cost').setValue('');
    this.costDetail.get('total_cost').setValue('');
    this.costDetail.get('total_price').setValue('');
    this.costDetail.get('margin').setValue('');
    this.costDetail.get('is_service_price').setValue(false);
  }
  ngOnInit(): void {
    $(document).ready(() => {
      $('#clearButton').on('click', () => {
        this.clearCostDetail();
      });
    });
    console.log('lab', this.labCostingData);
    if (this.labCostingData) {
      if (this.labCostingData.cost_details.length > 0) {
        while (this.costDetails.length) {
          this.costDetails.removeAt(0);
        }
        for (let i = 0; i < this.labCostingData.cost_details.length; i++) {
          this.costDetails.push(
            this.costDetailsArray(this.labCostingData.cost_details[i]),
          );
        }
        this.costDetail.patchValue({
          over_head_cost: this.labCostingData.over_head_cost,
          margin: this.labCostingData.margin,
        });
        this.calculateTotal();
      }
    } else {
      this.clearCostDetail();
    }
  }
}
