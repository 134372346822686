import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './layouts/layouts.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderService,
  SPINNER,
} from 'ngx-ui-loader';
import { UserVerificationComponent } from './auth/user-verification/user-verification.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { HospitalService } from './services/hospital.service';
import { ProfileService } from './services/profile.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResponseInterceptor } from './interceptor/response-interceptor';
import { DashboardComponent } from './superadmin/dashboard/dashboard.component';
import { DashboardService } from './services/dashboard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OnlineLabReportComponent } from './online-lab-report/online-lab-report.component';
import { LabTestService } from './services/lab-test.service';
import { EmployeeRegistrationService } from './services/employee-registration.service';
import { LocationService } from './services/location.service';
import { OtSchedulingComponent } from './superadmin/ot-scheduling/ot-scheduling.component';
import { OtService } from './services/ot.service';
import { LoginV2Component } from './auth/login-v2/login-v2.component';
import { WorkerPrintComponent } from './worker-print/worker-print.component';
import { PatientService } from './services/patient.service';
import { DoctorSurveyComponent } from './doctor-survey/doctor-survey/doctor-survey.component';
import { DoctorSurveyReportComponent } from './doctor-survey/doctor-survey-report/doctor-survey-report.component';
import { DepartmentsbyhospitalService } from './services/departmentsbyhospital.service';
import { DoctorSurveyService } from './services/doctor-survey.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RegisterOrganizationComponent } from './Register/register-organization/register-organization.component';
import { SystemConfigurationService } from './services/system-configuration.service';
import { ForgotPasswordV2Component } from './auth/forgot-password-v2/forgot-password-v2.component';
import { OnlineLabReportV2Component } from './online-lab-report-v2/online-lab-report-v2.component';
import { LabPdfReportComponent } from './reports/lab-pdf-report/lab-pdf-report.component';
import { LabModule } from './pages/lab/lab.module';
import { RadPdfReportComponent } from './reports/rad-pdf-report/rad-pdf-report.component';
import { RadiologyModule } from './pages/radiology/radiology.module';
// import { CategoryWiseReportV2Component } from './Reports/category-wise-report-v2/category-wise-report-v2.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#OOACC1',
  bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  bgsType: SPINNER.rectangleBounce,
  fgsColor: '#337AB7',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  fgsType: SPINNER.ballScaleMultiple,
  // logoUrl: 'assets/images/loder-red.gif',
  // pbColor: '#FF0000',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserVerificationComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    OnlineLabReportComponent,
    OtSchedulingComponent,
    LoginV2Component,
    WorkerPrintComponent,
    DoctorSurveyComponent,
    DoctorSurveyReportComponent,
    RegisterOrganizationComponent,
    ForgotPasswordV2Component,
    OnlineLabReportV2Component,
    LabPdfReportComponent,
    RadPdfReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    LayoutsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    LabModule,
    RadiologyModule,
  ],
  providers: [
    HospitalService,
    ProfileService,
    DashboardService,
    LabTestService,
    LocationService,
    SystemConfigurationService,
    OtService,
    PatientService,
    NgxUiLoaderService,
    HospitalService,
    DepartmentsbyhospitalService,
    DoctorSurveyService,
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
