<div>
  <!--begin::Card-->
  <div class="card mb-4">
    <div class="card-body user-card-body">
      <div class="">
        <div class="row m-0 mb-1">
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Condition</label>
              <select
                class="form-control form-control-sm dischargeType"
                (change)="updateDischargeType()"
                [(ngModel)]="patientDischarge.discharge_type"
                [disabled]="
                  dischargedPatientData?.is_returned === true ||
                  dischargedPatientData?.discharge_type === 'Deceased'
                "
              >
                <option value="">Select</option>
                <option value="Normal">Normal</option>
                <option value="Refer To">Refer To</option>
                <option value="Deceased">Deceased</option>
                <option value="LAMA">LAMA</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-12 mb-1">
            <label>Discharge Template</label>
            <select
              class="form-control form-control-sm"
              [(ngModel)]="dischargeTempsValue"
              (change)="updateDischargeTemplate($event.target.value)"
            >
              <option value="">Select</option>
              <option
                [value]="template.id"
                *ngFor="let template of reportsTemplateData"
              >
                {{ template.name }}
              </option>
            </select>
          </div>
          <div
            class="col-12 mt-5"
            *ngFor="
              let template of patientDischarge.template_data;
              let i = index
            "
          >
            <div class="form-group">
              <label>{{ template.heading }}</label>
              <div
                class="summernote"
                id="dischargeReportsTemplate{{ i }}"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-4" *ngIf="patientDischarge.discharge_type !== 'Deceased'">
    <div class="card-body user-card-body">
      <div class="">
        <div class="row mb-2">
          <div class="col-6">
            <h5 class="pl-4">Medicines</h5>
          </div>
          <div class="col-6 text-right">
            <button
              type="button"
              class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mr-5"
              (click)="addMoreMedicineDis()"
            >
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                    <path
                      d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </button>
          </div>
        </div>

        <div
          class=""
          style="clear: both"
          *ngFor="let medicine of patientDischarge.medications; let i = index"
        >
          <div class="row m-0 mb-4 mb-sm-0 mb-md-0 mb-lg-0">
            <div class="col-md-4 col-sm-6" id="medicineSearchDis{{ i }}">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Name Of medicine <span class="text-danger">*</span></label
                >
                <input
                  class="form-control form-control-sm"
                  placeholder="Search product name"
                  id="medicineNameDis{{ i }}"
                  [(ngModel)]="medicine.name"
                  type="text"
                  autocomplete="off"
                  (keyup)="getItemsListMain(i)"
                  (click)="getItemsListMain(i)"
                  (focusout)="focusOutDis(i)"
                />
                <div
                  class="search-result-main"
                  *ngIf="medicine.name.length > 2"
                >
                  <p
                    *ngIf="
                      productListData.length === 0 &&
                      productSearchResult === false
                    "
                  >
                    Searching...
                  </p>
                  <p
                    *ngIf="
                      productListData.length === 0 &&
                      productSearchResult === true
                    "
                  >
                    No Result Found
                  </p>
                  <span *ngIf="productListData.length > 0">
                    <p
                      *ngFor="let product of productListData"
                      (click)="setProductDis(product, i)"
                    >
                      {{ product.product_name }}
                    </p>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 pl-0">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Dose Type<span class="text-danger">*</span></label
                >
                <select
                  class="form-control form-control-sm"
                  id="stockTypes{{ i }}"
                  [(ngModel)]="medicine.stoc_type_id"
                  (change)="updateDoses($event.target.value, i)"
                >
                  <option value="">Select</option>
                  <option *ngFor="let data of doseTypeList" [value]="data.id">
                    {{ data?.name }}
                  </option>
                  <!-- <option value="liquid">Liquid</option>
                  <option value="tablet">Tablet</option>
                  <option value="capsules">Capsules</option>
                  <option value="drops">Drops</option>
                  <option value="injections">Injections</option>
                  <option value="syrup">Syrup</option>
                  <option value="supply">Supply</option>
                  <option value="gel">Gel</option>
                  <option value="powder">Powder</option>
                  <option value="ointment">Ointment</option>
                  <option value="surgical">Surgical</option>
                  <option value="spray">Spray</option> -->
                </select>
              </div>
              <!--                        <input type="date"  class="form-control form-control-sm" id="medicineStart{{i}}" [(ngModel)]="medicine.from_date">-->
            </div>

            <div class="col-md-2 col-sm-6">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >From Date</label
                >
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="medicineStartDis{{ i }}"
                  min="{{ today }}"
                  [(ngModel)]="medicine.from_date"
                  (change)="updateFromDateDischarge(medicine.from_date, i)"
                />
              </div>
            </div>
            <div class="col-md-1 col-sm-6">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Days</label
                >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  placeholder="days"
                  min="1"
                  id="medicineNoOfDaysDis{{ i }}"
                  (keyup)="updateMedicineToDateDis(i)"
                  [(ngModel)]="medicine.no_of_days"
                />
              </div>
            </div>
            <div class="col-md-2 col-sm-6">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >To Date</label
                >
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="medicineEndDis{{ i }}"
                  min="{{ medicine.from_date }}"
                  [(ngModel)]="medicine.to_date"
                  (change)="updateNoOfDaysDis(i)"
                />
              </div>
            </div>

            <div class="col-md-1 col-sm-2 col-12 align-self-center text-right">
              <button
                type="button"
                class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
                (click)="removeMedicineDis(i)"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                      <path
                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </button>
            </div>

            <div class="col-md-2 col-sm-6 col-12">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Dose</label
                >
                <select
                  class="form-control form-control-sm"
                  id="doseType{{ i }}"
                  [(ngModel)]="medicine.dos_type_id"
                >
                  <option value="">Select</option>
                  <option *ngFor="let data of doses[i]" [value]="data.id">
                    {{ data?.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2 col-sm-6">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Frequency<span class="text-danger">*</span></label
                >
                <select
                  class="form-control form-control-sm"
                  id="frequency{{ i }}"
                  [(ngModel)]="medicine.dosee_id"
                >
                  <option value="">Select</option>
                  <option *ngFor="let data of frequencyList" [value]="data.id">
                    {{ data?.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-2 col-sm-6 col-12 pl-0">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Route</label
                >

                <select
                  class="form-control form-control-sm"
                  id="routeId{{ i }}"
                  [(ngModel)]="medicine.routee_id"
                >
                  <option value="">Route</option>
                  <option *ngFor="let data of routeList" [value]="data.id">
                    {{ data?.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-5 col-sm-6">
              <div class="form-group">
                <label *ngIf="(i === 0 && width > 575) || width < 576"
                  >Comments</label
                >
                <input
                  class="form-control form-control-sm"
                  type="text"
                  [(ngModel)]="medicine.comment"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <!--                  <button type='button' class="btn btn-secondary" style="float: right" *ngIf="patientDischarge.medications.length === 0" (click)="addMoreMedicineDis(0)"><i class="fa fa-plus"></i></button>-->
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-4">
    <div class="card-body user-card-body">
      <!--                  style="position: static;padding-left: 0;padding-right: 0"-->
      <div class="row mb-2">
        <div class="col-6">
          <h5 class="pl-4">Operations</h5>
        </div>
        <div class="col-6 text-right">
          <button
            type="button"
            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
            (click)="addMoreOperationDetails()"
          >
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Code\Plus.svg--><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <!--                                          <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>-->
                  <path
                    d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z"
                    fill="#000000"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </button>
        </div>
      </div>

      <div
        class="row m-0 mb-4 mb-sm-0 mb-md-0 mb-lg-0"
        *ngFor="
          let operation of patientDischarge.operation_details;
          let i = index
        "
      >
        <div class="col-md-3 col-sm-3">
          <div class="form-group">
            <label *ngIf="(i === 0 && width > 575) || width < 576"
              >Operation Details <span class="text-danger">*</span></label
            >
            <textarea
              rows="1"
              class="form-control form-control-sm"
              id="operationDetailsDis{{ i }}"
              [(ngModel)]="operation.detail"
            ></textarea>
          </div>
        </div>

        <div class="col-md-2 col-sm-2">
          <div class="form-group">
            <label *ngIf="(i === 0 && width > 575) || width < 576"
              >Anesthesia Type <span class="text-danger">*</span></label
            >
            <select
              class="form-control form-control-sm"
              id="operationLaGaDis{{ i }}"
              [(ngModel)]="operation.la_ga"
            >
              <option value="">Select</option>
              <option value="Local Anesthesia">Local Anesthesia</option>
              <option value="General Anesthesia">General Anesthesia</option>
              <option value="Regional Anesthesia">Regional Anesthesia</option>
            </select>
          </div>
        </div>

        <div class="col-md-2 col-sm-2">
          <div class="form-group">
            <label *ngIf="(i === 0 && width > 575) || width < 576"
              >Surgeon <span class="text-danger">*</span></label
            >
            <select
              class="form-control form-control-sm doctorSelectpicker selectpicker"
              data-live-search="true"
              data-container="body"
              multiple
              id="operationSurgeonDis{{ i }}"
              [(ngModel)]="operation.surgeon_ids"
            >
              <option value="" [selected]="true" [disabled]="true">
                Select
              </option>
              <option [value]="d.id" *ngFor="let d of doctors">
                {{ d.first_name }} {{ d.last_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2 col-sm-2">
          <div class="form-group">
            <label *ngIf="(i === 0 && width > 575) || width < 576"
              >Assistants <span class="text-danger">*</span></label
            >
            <select
              class="form-control form-control-sm doctorSelectpicker selectpicker"
              data-live-search="true"
              data-container="body"
              multiple
              id="operationAssistantDis{{ i }}"
              [(ngModel)]="operation.assistant_ids"
            >
              <option value="" [selected]="true" [disabled]="true">
                Select
              </option>
              <option [value]="d.id" *ngFor="let d of doctors">
                {{ d.first_name }} {{ d.last_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 col-sm-2">
          <div class="form-group">
            <label *ngIf="(i === 0 && width > 575) || width < 576"
              >Operation Date</label
            >
            <input
              type="date"
              id="operationDate{{ i }}"
              [(ngModel)]="operation.operation_date"
              class="form-control form-control-sm"
              max="{{ today }}"
            />
          </div>
        </div>
        <div class="col-md-1 col-sm-1 col-12 align-self-center text-right">
          <button
            type="button"
            class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm"
            (click)="removeOperationDis(i)"
          >
            <span class="svg-icon svg-icon-primary svg-icon-2x">
              <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\General\Trash.svg--><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                  <path
                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body user-card-body">
      <div class="">
        <div class="row m-0 mb-2">
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Duty Doctor <span class="text-danger">*</span></label>
              <div class="disDutyDoctorMain">
                <select
                  class="form-control form-control-sm docPicker selectPicker"
                  id="disDutyDoctor"
                  data-container="body"
                  data-live-search="true"
                  [(ngModel)]="patientDischarge.duty_doctor_id"
                >
                  <option value="" [disabled]="true">Select doctor</option>
                  <option [value]="d.id" *ngFor="let d of doctors">
                    {{ d.first_name }} {{ d.last_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12 mb-1">
            <div class="form-group">
              <label>Chief Complaint & Clinical Finding</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.chief_complaint_finding"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Doctor Group</label>
              <select
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.doctor_group_id"
              >
                <option value="">Select doctor group</option>
                <option [value]="dg.id" *ngFor="let dg of doctor_groups">
                  {{ dg.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-12 mb-1">
            <div class="form-group">
              <label>Follow up instructions</label>
              <textarea
                rows="4"
                class="textareas"
                id="followUpInstructions"
                [(ngModel)]="patientDischarge.follow_up"
                name="followUp_instructions"
              ></textarea>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Operative Finding</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.operative_finding"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Operative Procedure</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.operative_procedure"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Immediate Complications</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.immediate_complication"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 mb-1">
            <div class="form-group">
              <label>Late Complications</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.late_complication"
              />
            </div>
          </div>
        </div>
        <div
          class="row m-0"
          *ngIf="patientDischarge.discharge_type == 'Deceased'"
        >
          <div class="col-md-3 col-sm-3 col-12 align-self-center">
            <div class="form-group">
              <label class="checkbox checkbox-sm">
                <input
                  type="checkbox"
                  [(ngModel)]="patientDischarge.recevied_dead"
                />
                <span class="mr-2"></span>Received Dead
              </label>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 align-self-center">
            <div class="form-group">
              <label>Date / Time of Death</label>
              <!--                          <input type="text" class="form-control form-control-sm" [(ngModel)]="patientDischarge.discharge_date_time">-->
              <input
                type="text"
                class="form-control form-control-sm datetimepicker-input"
                id="dischargeDate"
                data-target="#dischargeDate"
                data-toggle="datetimepicker"
                (keydown)="preventChangeDate()"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Diagnosis</label>
              <div class="search_box">
                <input
                  class="form-control form-control-sm"
                  type="text"
                  [(ngModel)]="diagnosisSearch"
                  (keyup)="getDiagnosisList()"
                  (click)="getDiagnosisList()"
                />
                <div
                  class="search-result-main"
                  *ngIf="diagnosisSearch.length > 2"
                >
                  <p
                    *ngIf="
                      diagnosisListData.length === 0 &&
                      diagnosisSearchResult === false
                    "
                  >
                    Searching...
                  </p>
                  <p
                    *ngIf="
                      diagnosisListData.length === 0 &&
                      diagnosisSearchResult === true
                    "
                  >
                    No Result Found
                  </p>
                  <span *ngIf="diagnosisListData.length > 0">
                    <p
                      *ngFor="let diagnosis of diagnosisListData"
                      (click)="setDiagnosis(diagnosis)"
                    >
                      {{ diagnosis.name }}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Type</label>
              <select
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.diagnosis_type"
              >
                <option value="ProvisionalDiagnosis">Provisional</option>
                <option value="DifferentialDiagnosis">Differential</option>
                <option value="FinalDiagnosis">Final</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Post Mortem</label>
              <select
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.post_mortem"
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Comments</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.comments_01"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Primary Cause of Death</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.primary_cause_of_death"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Secondary Cause of Death</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.secondary_cause_of_death"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Underlying Cause</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.underlying_cause"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-12 p-l-0">
            <div class="form-group">
              <label>Treatment</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.comments_02"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12 p-l-0">
            <div class="form-group">
              <label>Significant Conditions</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.significant_condition"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12 p-l-0">
            <div class="form-group">
              <label>Contributing to Death</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="patientDischarge.contributing_to_death"
              />
            </div>
          </div>
        </div>
        <div class="row m-0 mt-4">
          <div class="col-lg-4 col-md-4 col-sm-6 col-12">
            <div class="form-group">
              <label>Associated Diagnosis</label>
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Diagnosis"
                [(ngModel)]="patientDischarge.associated_diagnostic"
              />
              <!--                          <textarea class="form-control textareas" rows="3" id="associatedDiagnostic" [(ngModel)]="patientDischarge.associated_diagnostic" name="associatedDiagnostic"></textarea>-->
            </div>
          </div>
          <div class="col-12 text-right">
            <button
              class="btn btn-primary btn-sm mr-5"
              (click)="patientDischargeFunPrint()"
              *ngIf="dischargedPatientData?.id || dischargeData?.id"
            >
              Print
            </button>
            <button
              class="btn btn-primary btn-sm mr-5"
              (click)="patientDischargeFun(true)"
            >
              Save
            </button>
            <button
              class="btn btn-primary btn-sm"
              (click)="patientDischargeFun(false)"
            >
              Save & Discharge
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Card-->
</div>

<div class="example-preview">
  <div>
    <ng-template #dischargeCompleteAlert let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-card-body">
          <h6>This patient will be removed from ward.</h6>
          <div class="bootbox-body">
            <input
              type="checkbox"
              [(ngModel)]="isChecked"
              style="margin-right: 5px"
            />
            Are you sure you want to discharge this patient?
            <br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click')"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="patientDischargeConfirm()"
          >
            Confirm
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
