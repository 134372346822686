<div class="d-flex">
  <div class="col-3 pl-0">
    <div class="card">
      <div class="card-body card-custom p-5">
        <div class="d-flex pb-1">
          <div>
            <div class="symbol symbol-60 symbol-light-success mr-5">
              <span class="symbol-label">
                <img
                  [src]="patient?.profile_image ? patient.profile_image : getFallbackImage()"
                  class="h-100 align-self-end"
                  alt="Patient Profile"
                />
              </span>
            </div>
          </div>
          <div>
            <div class="mt-2">
              <p class="mb-1">
                {{ patient?.first_name + " " + patient?.last_name }}
              </p>
            </div>
            <div class="">
              <span class=""
                >{{ patient?.mrn }} / {{ patient?.age }} /
                {{ patient?.gender }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-9 pr-0 pl-0">
    <div class="card">
      <div class="card-body card-custom p-5">
        <div class="d-flex">
          <div class="col-3">
            <label>Department <span class="text-danger">*</span></label>
            <select
              class="form-control form-control-sm bootstrap-select-container departmentSelectPicker"
              data-live-search="true"
              id="departmentSelectPicker"
              data-none-selected-text="Select"
              (change)="getDoctors($event.target.value)"
              [(ngModel)]="department_Id"
            >
              <option value="">select</option>
              <option *ngFor="let dep of departments" [value]="dep.id">
                {{ dep.title }}
              </option>
            </select>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label> Doctor <span class="text-danger">*</span></label>
              <select
                class="form-control form-control-sm bootstrap-select-container doctorSelectPicker"
                (change)="getDepartments($event.target.value)"
                data-none-selected-text="Select"
                data-container="body"
                data-live-search="true"
                [(ngModel)]="Doctor_Id"
              >
                <option value="" [selected]="true" [disabled]="true">
                  Select
                </option>
                <option [value]="doc.id" *ngFor="let doc of doctors_by_dept">
                  {{ doc.first_name }} {{ doc.last_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label>Select Month <span class="text-danger">*</span></label>
              <input
                type="text"
                name="procedureDate"
                class="form-control form-control-sm month"
                readonly="readonly"
                [(ngModel)]="currentMonth"
              />
            </div>
          </div>
          <div class="col-4 pr-0 pl-0 pt-3">
            <div class="d-flex col-12 pr-0 pl-0">
              <div class="d-flex col-4 pl-0 pr-0">
                <div class="box faded"></div>
                <div>Available</div>
              </div>
              <div class="d-flex col-4">
                <div class="box blue"></div>
                <div>Booked</div>
              </div>
              <div class="d-flex col-4 pr-0">
                <div class="box green"></div>
                <div>Confirmed</div>
              </div>
            </div>
            <div class="d-flex col-12 pt-2 pr-0 pl-0">
              <div class="d-flex col-4 pl-0 pr-0">
                <div class="box holdPatients"></div>
                <div>Reserved</div>
              </div>
              <div class="d-flex col-8 pr-0">
                <div class="box yellow"></div>
                <div>Selected Patient Slots</div>
              </div>
            </div>
            <!--            <div class="d-flex col-12 pt-2 pr-0 pl-0">-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5">
  <div class="card">
    <div class="card-body card-custom">
      <div class="d-flex justify-content-end col-12 text-right mb-5">
        <div class="d-flex mr-2">
          <div>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              [disabled]="
                doctorSlotsData.length === 0 &&
                (toType === '' || toType === 'previous')
              "
              (click)="previousWeek('previous')"
            >
              <i class="fa fa-solid fa-chevron-left"></i>
              <span>Previous</span>
            </button>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              [disabled]="
                doctorSlotsData.length === 0 &&
                (toType === '' || toType === 'forward')
              "
              (click)="nextWeek('forward')"
            >
              <span style="padding-left: 7px; padding-right: 5px">Next </span
              ><i class="fa fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="doctorSlotsData.length > 0">
        <div class="d-flex">
          <div
            style="width: 14%; padding-right: 10px"
            *ngFor="let daySlots of doctorSlotsData; let i = index"
          >
            <div class="text-center" style="padding-left: 4px">
              <div>
                <h4 class="text-uppercase">
                  {{ daySlots.date | date: "EEEE" }}
                </h4>
              </div>
              <div>
                {{ daySlots.date | date: "d MMM yyyy" }}
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="d-flex">
          <div
            class="daysMain"
            style="width: 14%; padding-right: 10px"
            *ngFor="let daySlots of doctorSlotsData; let i = index"
          >
            <ng-container *ngFor="let slot of daySlots.slots; let j = index">
              <!--              <div class="text-center pb-1 pt-1" id="dayMain{{i}}" style="border: 1px solid #938a8a; border-radius: 4px; margin-bottom: 5px; padding-left: 4px">-->
              <!--                <div class="">-->
              <div
                class="dropdown dropdown-inline text-center pb-1 pt-1"
                ngbTooltip="{{
                  slot.full_name && slot.mrn
                    ? slot.full_name + '-' + slot.mrn
                    : ''
                }}"
                placement="top"
                style="
                  border-radius: 4px;
                  margin-bottom: 5px;
                  padding-left: 4px;
                "
                [ngClass]="{
                  'pointer-events-no':
                    isPastDate(daySlots.date, slot.start_time) &&
                    (slot.slot_status === 'available' ||
                      slot.slot_status === 'hold')
                }"
              >
                <button
                  [ngClass]="{
                    'btn-slot': slot.slot_status === 'available',
                    'btn-reserved': slot.slot_status === 'hold',
                    'btn-confirmed': slot.slot_status === 'booked',
                    'btn-booked': slot.slot_status === 'reserved',
                    'btn-selected-booked':
                      slot.patient_id === this.patient.id &&
                      slot.slot_status === 'reserved',
                    'btn-selected-confirm':
                      slot.patient_id === this.patient.id &&
                      slot.slot_status === 'booked'
                  }"
                  type="button"
                  class="btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="min-width: 160px !important"
                >
                  {{ slot.start_time }} - {{ slot.end_time }}
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    *ngIf="slot.slot_status === 'available' && type !== 'OPD'"
                    class="dropdown-item cursor-pointer"
                    (click)="reserveSlot(slot.id, 'hold')"
                    >Reserve</a
                  >
                  <a
                    *ngIf="
                      slot.slot_status === 'available' ||
                      slot.slot_status === 'hold'
                    "
                    class="dropdown-item cursor-pointer"
                    (click)="reserveSlot(slot.id, 'reserved')"
                    >Book</a
                  >
                  <a
                    *ngIf="
                      (slot.slot_status === 'reserved' ||
                      slot.slot_status === 'hold') && type !== 'OPD'
                    "
                    class="dropdown-item cursor-pointer"
                    (click)="openBookedModal(slot.id)"
                    >Confirm</a
                  >
                  <a
                    *ngIf="
                     ( slot.slot_status === 'hold' ||
                      slot.slot_status === 'reserved') && type !== 'OPD'
                    "
                    class="dropdown-item cursor-pointer"
                    (click)="cancelReservation(slot.id)"
                    >Cancel</a
                  >
                  <a
                    *ngIf="slot.slot_status === 'booked'"
                    class="dropdown-item cursor-pointer"
                    >Detail</a
                  >
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!--Proceed To Pay Modal-->
<div
  class="modal fade"
  id="proceedToPayModal"
  tabindex="-1"
  style="z-index: 1051 !important"
  role="dialog"
  aria-labelledby="ModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="col-4">
            <label>Visit Type</label>
            <select
              class="form-control form-control-sm"
              [(ngModel)]="visit_Type"
              [ngClass]="{
                'is-invalid': isSubmitVisitType
              }"
              (change)="fetchVisitService($event.target.value)"
            >
              <option value="" [disabled]="true">Select</option>
              <ng-container *ngFor="let lov of visitType">
                <option [value]="lov.id">{{ lov.key }}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-4">
            <label>Payment Mode</label>
            <select
              class="form-control form-control-sm"
              [(ngModel)]="paymentAdvance.mode_of_payment"
              (change)="payemtnChange()"
            >
              <option [ngValue]="'Cash'" [selected]="true">Cash</option>
              <option [ngValue]="'Cheque'">Cheque</option>
              <option [ngValue]="'Credit Card'">Credit Card</option>
              <option [ngValue]="'Bank Transfer'">Bank Transfer</option>
            </select>
          </div>
          <div class="col-4">
            <div class="text-center">
              <label>Fee</label>
              <div class="mt-2">
                <strong>{{ visitFee }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div
            class="col-4 mt-3"
            *ngIf="
              paymentAdvance.mode_of_payment === 'Cheque' ||
              paymentAdvance.mode_of_payment === 'Credit Card' ||
              paymentAdvance.mode_of_payment === 'Bank Transfer'
            "
          >
            <label *ngIf="paymentAdvance.mode_of_payment === 'Cheque'"
              >Cheque #</label
            >
            <label *ngIf="paymentAdvance.mode_of_payment === 'Credit Card'"
              >Receipt #</label
            >
            <label *ngIf="paymentAdvance.mode_of_payment === 'Bank Transfer'"
              >Transaction ID</label
            >
            <input
              class="form-control form-control-sm"
              placeholder="Reciept Id"
              [(ngModel)]="paymentAdvance.receipt_number"
            />
          </div>
          <div
            class="col-4 mt-3"
            *ngIf="
              paymentAdvance.mode_of_payment === 'Credit Card' ||
              paymentAdvance.mode_of_payment === 'Bank Transfer'
            "
          >
            <label>Bank Name</label>
            <select
              class="form-control form-control-sm selectPickerBankDetail"
              [(ngModel)]="paymentAdvance.bank_id"
            >
              <option [value]="''">Select Branch</option>
              <ng-container *ngFor="let bank of bankList">
                <option [value]="bank.id">{{ bank.bank_name }}</option>
              </ng-container>
            </select>
          </div>
          <div
            class="col-4 mt-3"
            *ngIf="paymentAdvance.mode_of_payment !== 'Cash'"
          >
            <label>Payment Detail</label>
            <input
              [(ngModel)]="paymentAdvance.payment_details"
              class="form-control form-control-sm"
              name="payment_details"
              placeholder="payment detail"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
          (click)="closeProcessToPayModal()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          [disabled]="diableSubmitButton"
          (click)="submitSlotbooking()"
        >
          Process to Pay
        </button>
      </div>
    </div>
  </div>
</div>
