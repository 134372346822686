import {NgModule} from '@angular/core';
import {UploadImagesComponent} from './upload-images/upload-images.component';
import {LabParameterComponent} from './lab-parameter/lab-parameter.component';
import {PatientDetailsComponent} from './patient-details/patient-details.component';
import {PatientInfoComponent} from './patient-info/patient-info.component';
import {ServicesHistoryComponent} from './services-history/services-history.component';
import {AdmissionHistoryComponent} from './admission-history/admission-history.component';
import {FamilyInfoComponent} from './family-info/family-info.component';
import {ServiceCostingDetailComponent} from './service-costing-detail/service-costing-detail.component';
import {SummernotesComponent} from './summernotes/summernotes.component';
import {ProductSearchComponent} from './product-search/product-search.component';
import {CheckListComponent} from './check-list/check-list.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {
  VisitingEmployeeConfigurationModule
} from '../human-resource-management/visiting-employee-configuration/visiting-employee-configuration.module';
import {CustomPipeModuleModule} from '../../custom-pipe-module/custom-pipe-module.module';
import { OpdTemplateModalComponent } from './opd-template-modal/opd-template-modal.component';
import { PrescriptionReportDataComponent } from './prescription-report-data/prescription-report-data.component';
import { PrescriptionReviewHistoryComponent } from './prescription-review-history/prescription-review-history.component';
import { OpdReviewHistoryPrintComponent } from './opd-review-history-print/opd-review-history-print.component';
import { PrescriptionPhysicalExaminationComponent } from './prescription-physical-examination/prescription-physical-examination.component';
import { PatientAdmissionModalComponent } from './patient-admission-modal/patient-admission-modal.component';

@NgModule({
  declarations: [
    UploadImagesComponent,
    LabParameterComponent,
    PatientDetailsComponent,
    PatientInfoComponent,
    ServicesHistoryComponent,
    AdmissionHistoryComponent,
    FamilyInfoComponent,
    ServiceCostingDetailComponent,
    SummernotesComponent,
    ProductSearchComponent,
    CheckListComponent,
    OpdTemplateModalComponent,
    PrescriptionReportDataComponent,
    PrescriptionReviewHistoryComponent,
    OpdReviewHistoryPrintComponent,
    PrescriptionPhysicalExaminationComponent,
    PatientAdmissionModalComponent,
  ],
    exports: [
        UploadImagesComponent,
        LabParameterComponent,
        PatientDetailsComponent,
        PatientInfoComponent,
        ServicesHistoryComponent,
        AdmissionHistoryComponent,
        FamilyInfoComponent,
        ServiceCostingDetailComponent,
        SummernotesComponent,
        ProductSearchComponent,
        CheckListComponent,
        OpdTemplateModalComponent,
        PrescriptionReportDataComponent,
        PrescriptionReviewHistoryComponent,
        PrescriptionPhysicalExaminationComponent,
        PatientAdmissionModalComponent,
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    VisitingEmployeeConfigurationModule,
    CustomPipeModuleModule,
  ],
})
export class SharedModule {
}
