import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-lab-controls-v2',
  templateUrl: './lab-controls-v2.component.html',
  styleUrls: ['./lab-controls-v2.component.css'],
})
export class LabControlsV2Component implements OnInit {
  settingsForm: FormGroup;
  submitted = false;
  settingData: any;
  // for Logo image
  croppedImageHeader1 = '';
  croppedImageHeader2 = '';
  imageChangeHeader1 = '';
  imageChangeHeader2 = '';
  showCropperLogo = false;
  showCropperLogoHeader2 = false;
  // for Home image
  croppedImageFooter1 = '';
  croppedImageFooter2 = '';
  imageChangedFooter1 = '';
  imageChangedFooter2 = '';
  showCropperFooter1 = false;
  showCropperFooter2 = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private labTestService: LabTestService,
  ) {
    this.settingsForm = this.formBuilder.group({
      web_report: [true],
      is_header: [true],
      is_header_img: [false],
      header_img_url: [''],
      header_img_url2: [''],
      is_footer: [true],
      is_footer_img: [false],
      footer_img_url: [''],
      footer_img_url2: [''],
      lab_status: [''],
      show_worklist: [1],
      auto_receive: [false],
      auto_verify: [false],
      enable_external_lis: [false],
      external_lis_base_url: [''],
      print_unpaid_invoice: [false],
      print_unapproved_discount: [false],
      stop_unpaid_invoice_in_collection: [false],
    });
  }

  updateSettings(): any {
    this.submitted = true;
    if (this.settingsForm.invalid) {
      return;
    }
    if (
      this.settingData?.header_img_url === '' &&
      this.croppedImageHeader1 === '' &&
      this.settingData?.header_file2 === '' &&
      this.croppedImageHeader2 === ''
    ) {
      toastr.error('Please add logo');
      return;
    }
    if (
      this.settingData?.footer_img_url === '' &&
      this.croppedImageFooter1 === '' &&
      this.settingData?.footer_file2 === '' &&
      this.croppedImageFooter2 === ''
    ) {
      toastr.error('Please add Home Image');
      return;
    }
    // for Logo image
    if (this.croppedImageHeader1 !== '') {
      this.settingsForm.patchValue({
        header_img_url: this.croppedImageHeader1,
      });
    } else {
      this.settingsForm.patchValue({
        header_img_url: '',
      });
    }
    if (this.croppedImageHeader2 !== '') {
      this.settingsForm.patchValue({
        header_img_url2: this.croppedImageHeader2,
      });
    } else {
      this.settingsForm.patchValue({
        header_img_url2: '',
      });
    }
    // for Home image
    if (this.croppedImageFooter1 !== '') {
      this.settingsForm.patchValue({
        footer_img_url: this.croppedImageFooter1,
      });
    } else {
      this.settingsForm.patchValue({
        footer_img_url: '',
      });
    }
    if (this.croppedImageFooter2 !== '') {
      this.settingsForm.patchValue({
        footer_img_url2: this.croppedImageFooter2,
      });
    } else {
      this.settingsForm.patchValue({
        footer_img_url2: '',
      });
    }
    if (this.settingsForm.get('auto_receive').value === '') {
      this.settingsForm.get('auto_receive').setValue(false);
    }
    if (this.settingsForm.get('auto_verify').value === '') {
      this.settingsForm.get('auto_verify').setValue(false);
    }
    if (
      this.settingsForm.get('enable_external_lis').value === true &&
      this.settingsForm.get('external_lis_base_url').value === ''
    ) {
      toastr.error('External LIS Base Urls is Required');
      return;
    }
    console.log('this.settingsForm', this.settingsForm.value);
    this.ngxService.start();
    this.labTestService.updateLabsControlsv2(this.settingsForm.value).subscribe(
      (data) => {
        this.getSettingsData();
        // this.ngxService.stop();
        toastr.success('Updated successfully');
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.error);
      },
    );
  }

  get f(): any {
    return this.settingsForm.controls;
  }
  getSettingsData(): any {
    this.ngxService.start();
    this.labTestService.fetchLabsControlsv2().subscribe(
      (data) => {
        this.settingData = '';
        this.settingData = data;
        // this.showCropperLogo = !this.settingData.header_img_url;
        // this.showCropperFooter1 = !this.settingData.footer_img_url;
        this.ngxService.stop();
        this.settingsForm.patchValue({
          web_report: data.web_report || '',
          is_header: data.is_header || false,
          is_header_img: data.is_header_img || false,
          header_img_url: data.header_img_url || '',
          header_img_url2: data.header_file2 || '',
          is_footer: data.is_footer || false,
          is_footer_img: data.is_footer_img || false,
          footer_img_url: data.footer_img_url || '',
          footer_img_url2: data.footer_file2 || '',
          lab_status: data.lab_status || '',
          auto_receive: data.auto_receive || '',
          auto_verify: data.auto_verify || '',
          show_worklist: data.show_worklist,
          enable_external_lis: data.enable_external_lis,
          print_unpaid_invoice: data?.print_unpaid_invoice ?? false,
          print_unapproved_discount: data?.print_unapproved_discount ?? false,
          stop_unpaid_invoice_in_collection:
            data?.stop_unpaid_invoice_in_collection ?? false,
          external_lis_base_url: data.external_lis_base_url,
        });
        this.showCropperFooter1 = false;
        this.showCropperLogo = false;
        this.showCropperLogoHeader2 = false;
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.error);
      },
    );
  }
  imageCroppedHeader1(event: ImageCroppedEvent): any {
    this.croppedImageHeader1 = event.base64;
  }
  imageCroppedHeader2(event: ImageCroppedEvent): any {
    this.croppedImageHeader2 = event.base64;
  }
  selectHeaderImage1(): any {
    $('#imgHeader1Select').val('');
    $('#imgHeader1Select').click();
  }
  selectHeaderImage2(): any {
    $('#imgHeader2Select').val('');
    $('#imgHeader2Select').click();
  }

  // Image change function start
  fileChangeHeader1(event: any): void {
    this.showCropperLogo = true;
    this.imageChangeHeader1 = event;
  }
  fileChangeHeader2(event: any): void {
    this.showCropperLogoHeader2 = true;
    this.imageChangeHeader2 = event;
  }

  imageCroppedFooter1(event: ImageCroppedEvent): any {
    this.croppedImageFooter1 = event.base64;
  }
  imageCroppedFooter2(event: ImageCroppedEvent): any {
    this.croppedImageFooter2 = event.base64;
  }
  selectImageFooter1(): any {
    $('#imgSelectFooter1').val('');
    $('#imgSelectFooter1').click();
  }
  selectImageFooter2(): any {
    $('#imgSelectFooter2').val('');
    $('#imgSelectFooter2').click();
  }
  fileChangeFooter1(event: any): void {
    this.showCropperFooter1 = true;
    this.imageChangedFooter1 = event;
  }
  fileChangeFooter2(event: any): void {
    this.showCropperFooter2 = true;
    this.imageChangedFooter2 = event;
  }
  ngOnInit(): void {
    this.getSettingsData();
  }
}
