import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlascoService } from '../../../services/glasco.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteShareService } from '../../../services/route-share.service';
import { TaskManagementService } from '../../../services/task-management.service';
declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-task-priority',
  templateUrl: './task-priority.component.html',
  styleUrls: ['./task-priority.component.css'],
})
export class TaskPriorityComponent implements OnInit {
  priority: FormGroup;
  subType: FormGroup;
  typeList: any;
  subTypeId: any;
  subTypeList: any;
  showUpdateType = false;
  isAddType = false;
  isAddSubType = false;
  showUpdateSubType = false;
  typeId: '';
  screenName = '';
  constructor(
    private fb: FormBuilder,
    private taksServices: TaskManagementService,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  ngOnInit(): void {
    $('#addTypeModal').on('hide.bs.modal', () => {
      this.priority.reset({
        name: '',
      });
    });
    this.priority = this.fb.group({
      name: ['', Validators.required],
    });
    this.getProirityList();
  }
  addTags(): any {
    this.showUpdateType = false;
    $('#addTypeModal').modal('show');
  }
  addSubTypes(): any {
    this.showUpdateSubType = false;
    $('#addSubTypeModal').modal('show');
  }
  getTagUpdate(id, index, data): any {
    this.typeId = id;
    this.showUpdateType = true;
    this.ngxLoader.start();
    this.priority.patchValue({
      name: data.name,
    });

    this.ngxLoader.stop();
    $('#addTypeModal').modal('show');
  }

  submitType(): any {
    this.isAddType = true;
    if (this.priority.invalid) {
      return;
    }

    if (this.showUpdateType === false) {
      this.taksServices.addPriority(this.priority.value).subscribe(
        (data) => {
          this.isAddType = false;
          $('#addTypeModal').modal('hide');
          this.priority.reset({
            name: '',
          });
          this.ngxLoader.stop();
          this.getProirityList();
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
      $('#addTypeModal').modal('hide');
    } else {
      this.taksServices
        .updatePriority(this.priority.value, this.typeId)
        .subscribe(
          (data) => {
            this.isAddType = false;
            console.log(this.typeId);
            $('#addTypeModal').modal('hide');
            this.priority.reset({
              name: '',
            });
            this.showUpdateType = false;
            this.getProirityList();
            this.ngxLoader.stop();
          },
          (err) => {
            console.log('err', err);
            this.ngxLoader.stop();
            toastr.error(err.error.error);
          },
        );
    }
    this.getProirityList();
  }

  getProirityList(): any {
    this.taksServices.getPriority().subscribe(
      (data) => {
        this.typeList = data;
        console.log(this.typeList);
      },
      (err) => {
        console.log('err', err);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
