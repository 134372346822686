import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialysisService } from '../../../services/dialysis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { RouteShareService } from '../../../services/route-share.service';
import { ShareDataService } from '../../../services/share-data.service';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-dialysis-services-v2',
  templateUrl: './dialysis-services-v2.component.html',
  styleUrls: ['./dialysis-services-v2.component.css'],
})
export class DialysisServicesV2Component implements OnInit {
  pageDataList: any;
  page = 1;
  totalPages: any;
  perPage = 25;
  manufactureSearchValue = '';
  status = false;
  dataListingCSV: any = [];
  objOfManufactrureCSV: {};
  DialysisServicesForm: FormGroup;
  submitted = false;
  departments = [];
  sub_departments = [];
  departmentServices = [];
  department_code: any;
  screenName = '';
  updatedData: any;
  parentDepartmentId: any = '';
  updateDialysis = false;
  dialysisModal = false;
  constructor(
    private dialysisService: DialysisService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private departmentService: DepartmentsbyhospitalService,
    private sharedService: ShareDataService,
  ) {}
  editDialysisIndex(indexedValue): any {
    this.sharedService.sendUpdateDialysisData(indexedValue);
    this.dialysisModal = true;
    $('#dialysis-service').modal('show');
  }
  loadPage(event): any {
    this.searchFilter();
  }
  searchFilterValue(): any {
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.dialysisService
      .indexDialysisServiceV2(
        this.page,
        this.perPage,
        this.manufactureSearchValue,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.pageDataList = data;
          if (this.pageDataList.meta) {
            this.totalPages = this.pageDataList.meta.total_count;
          }
          this.pageDataList = this.pageDataList.dialysis_services;
          this.ref.markForCheck();
          this.getListDataCSV();
          this.getCSV();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  serviceCreateOrUpdate(event): any {
    this.searchFilter();
    $('#dialysis-service').modal('hide');
    this.dialysisModal = false;
  }
  getListDataCSV(): any {
    this.dataListingCSV = [];
    for (var i = 0; i < this.pageDataList.length; i++) {
      this.objOfManufactrureCSV = {
        'Service Code': this.pageDataList[i].service.billing_code,
        'Service Name': this.pageDataList[i].service.name,
        Status: this.pageDataList[i].is_active,
      };
      this.ref.markForCheck();
      this.dataListingCSV.push(this.objOfManufactrureCSV);
    }
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    this.searchFilter();
  }
  getCSV(): any {
    const array = [Object.keys(this.dataListingCSV[0])].concat(
      this.dataListingCSV,
    );
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
  }
  downloadCSV(): any {
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURI(this.getCSV());
    hiddenElement.target = '_blank';
    hiddenElement.download = 'DialysisServices.csv';
    hiddenElement.click();
  }
  ngOnInit(): void {
    $('#dialysis-service').on('hide.bs.modal', () => {
      this.updateDialysis = false;
    });
    this.searchFilter();
    // this.departmentsServices();
  }
}
