import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { HospitalService } from '../../../../services/hospital.service';
import * as moment from 'moment/moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlascoService } from '../../../../services/glasco.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-glasgow-coma',
  templateUrl: './glasgow-coma.component.html',
  styleUrls: ['./glasgow-coma.component.css'],
})
export class GlasgowComaComponent implements OnInit {
  @Input() patient: any;

  settingData: any;
  glascoDate: any;
  glascoTotal = 0;
  totalPointMorning = 0;
  totalPointEvening = 0;
  totalPointNight = 0;
  totalPointAfternoon = 0;
  shiftData: any;
  glascoList: any;
  // currentDate = formatDate(new Date(), 'DD/MM/YYYY', 'en');
  currentDate = new Date();
  glascoSelectedArray = [];
  glascoSubmitForm: FormGroup;
  isAddType = false;

  constructor(
    private hospitalService: HospitalService,
    private ngxLoader: NgxUiLoaderService,
    private glascoService: GlascoService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.glascoSubmitForm = this.formBuilder.group({
      shift: ['', Validators.required],
      employe_id: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.getSettingsData();
    this.glascoGetData('');
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.glascoGetData(changes.patient.currentValue);
    $('#glascoStartDate').val(moment(this.currentDate).format('yyyy-MM-DD'));
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetailsv2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {},
    );
  }

  glascoGetData(val): any {
    if ($('#glascoStartDate').val() === '') {
      $('#glascoStartDate').val(moment().format('YYYY-MM-DD'));
      // return;
    }
    this.glascoTotal = 0;
    this.shiftData = '';
    this.totalPointMorning = 0;
    this.totalPointEvening = 0;
    this.totalPointNight = 0;
    this.totalPointAfternoon = 0;
    this.glascoDate = moment($('#glascoStartDate').val()).format('DD-MM-YYYY');
    this.glascoService
      .getGlascoList(this.glascoDate, this.patient.id, this.patient.visit_id)
      .subscribe(
        (data) => {
          this.glascoList = data.data;
          setTimeout(() => {
            this.totalPointMorning = 0;
            this.totalPointEvening = 0;
            this.totalPointNight = 0;
            this.totalPointAfternoon = 0;
            for (let i = 0; i < this.glascoList.length; i++) {
              for (let j = 0; j < this.glascoList[i].sub_type.length; j++) {
                for (
                  let k = 0;
                  k < this.glascoList[i].sub_type[j].shift.length;
                  k++
                ) {
                  if (
                    this.glascoList[i].sub_type[j].shift[k].shift === 'morning'
                  ) {
                    this.totalPointMorning +=
                      this.glascoList[i].sub_type[j].shift[k].point;
                  }
                  if (
                    this.glascoList[i].sub_type[j].shift[k].shift === 'evening'
                  ) {
                    this.totalPointEvening +=
                      this.glascoList[i].sub_type[j].shift[k].point;
                  }
                  if (
                    this.glascoList[i].sub_type[j].shift[k].shift === 'night'
                  ) {
                    this.totalPointNight +=
                      this.glascoList[i].sub_type[j].shift[k].point;
                  }
                  if (
                    this.glascoList[i].sub_type[j].shift[k].shift ===
                    'afternoon'
                  ) {
                    this.totalPointAfternoon +=
                      this.glascoList[i].sub_type[j].shift[k].point;
                  }
                }
              }
            }
          }, 300);
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updateGlascoTypes(dataId, index, subIndex): any {
    for (let i = 0; i < this.glascoList[index].sub_type.length; i++) {
      this.glascoList[index].sub_type[i].is_selected = false;
    }
    this.glascoList[index].sub_type[subIndex].is_selected = true;
    setTimeout(() => {
      this.glascoTotal = 0;
      this.glascoSelectedArray = [];
      for (let i = 0; i < this.glascoList.length; i++) {
        for (let j = 0; j < this.glascoList[i].sub_type.length; j++) {
          if (this.glascoList[i].sub_type[j].is_selected === true) {
            this.glascoSelectedArray.push(this.glascoList[i].sub_type[j].id);
            this.glascoTotal =
              this.glascoTotal + this.glascoList[i].sub_type[j].points;
          }
        }
      }
    }, 300);
  }

  PrintGlasco(): any {
    window.open(
      'pdf-reports/glasgow-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&glasgow_date=' +
        this.glascoDate,
    );
    // window.print();
  }

  glascoSubmit(): any {
    if (this.glascoTotal === 0) {
      toastr.error('value is not Selected');
      return;
    }
    $('#glascoModal').modal('show');
    this.glascoSubmitForm.reset({
      shift: '',
      employe_id: '',
      password: '',
    });
  }

  glascoData(): any {
    this.isAddType = true;
    if (this.glascoSubmitForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.glascoService
      .addGlascoData(
        this.glascoSubmitForm.value,
        this.glascoSelectedArray,
        this.patient.visit_id,
        this.patient.id,
        this.glascoDate,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.isAddType = false;
          $('#glascoModal').modal('hide');
          this.glascoSubmitForm.patchValue({
            employe_id: '',
            password: '',
            shift: '',
          });
          this.glascoGetData('');
        },
        (err) => {
          toastr.error('User name and password are incorrect ');
          this.ngxLoader.stop();
        },
      );
  }
}
