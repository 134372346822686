import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabTestService } from '../../../services/lab-test.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { ExternalHospitalMaintenanceService } from '../../../services/external-hospital-maintenance.service';
import { LabconfigurationService } from '../../../services/labconfiguration.service';
import { ShareDataService } from '../../../services/share-data.service';
import { PatientTypeService } from '../../../services/patient-type.service';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-lab-test-modal',
  templateUrl: './lab-test-modal.component.html',
  styleUrls: ['./lab-test-modal.component.css'],
})
export class LabTestModalComponent implements OnInit {
  @Output() updatedData: EventEmitter<{ data: any; check: boolean }> =
    new EventEmitter<{ data: any; check: boolean }>();
  @Input() labHeadingConfigurations: any;
  LabTestForm: FormGroup;
  labTestSubmitted = false;
  departments = [];
  ehmList = [];
  labConfigurations = [];
  updateId: any;
  addLabTest = true;
  isOpenModal = false;
  patientTypes = [];
  labData: any;
  labCosting: any;
  showCostingDetail = false;
  isChecked = false;
  disabledBtn = false;
  updateCostingDetail: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private patientTypeService: PatientTypeService,
    private labTestService: LabTestService,
    private departmentsByHospitalService: DepartmentsbyhospitalService,
    private ehmService: ExternalHospitalMaintenanceService,
    private labService: LabconfigurationService,
    private formBuilder: FormBuilder,
    private sharedService: ShareDataService,
  ) {
    this.LabTestForm = this.formBuilder.group({
      profile_name: ['', Validators.required],
      profile_code: [''],
      department_id: ['', Validators.required],
      report_type: ['Customized', Validators.required],
      status: [true],
      color: ['#000000'],
      lab_code: [''],
      send_out_id: [''],
      specimen_id: [''],
      service_price: [''],
      replicate_service_pat_types: [''],
      lab_test_group: [''],
      reporting_time: [false],
      // parametersArray: this.formBuilder.array([this.addLabParameterGroup()])
    });
  }

  getCostingData(event): any {
    this.updateCostingDetail = event;
    if (this.updateCostingDetail.is_service_price) {
      this.LabTestForm.get('service_price').setValue(
        this.updateCostingDetail.total_price,
      );
      $('#service_price').prop('disabled', true);
    } else {
      $('#service_price').prop('disabled', false);
    }
  }
  newLabTest(): any {
    this.disabledBtn = true;
    setTimeout(() => {
      $('#departmentPicker').selectpicker('refresh');
      $('#reportTypePicker').selectpicker('refresh');
      $('#sendout').selectpicker('refresh');
      $('#Specimen').selectpicker('refresh');
    }, 1000);
    this.labTestSubmitted = true;

    if (this.LabTestForm.invalid) {
      this.disabledBtn = false;
      return;
    }
    console.log('isCheck', this.isChecked);
    if (this.isChecked === false && this.addLabTest) {
      toastr.error('Select Confirmation');
      this.disabledBtn = false;
      return;
    }
    const labTestFormValue = this.LabTestForm.value;
    labTestFormValue.cost_details = this.updateCostingDetail;
    this.ngxLoader.start();
    this.labTestService.createV2(labTestFormValue).subscribe(
      (value) => {
        this.ngxLoader.stop();
        toastr.success('Successfully saved new lab test');
        this.updatedData.emit({ data: value, check: true });
        this.addLabTest = true;
        $('#addLabModal').modal('hide');
        this.isOpenModal = false;
        this.disabledBtn = false;
        // this.pageDataListForListing.push(data.lab_investigation);
        setTimeout(() => {
          $('#copyParameter').selectpicker();
          $('#copyParameter').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.disabledBtn = false;
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  updateLabTestFun(): any {
    this.disabledBtn = true;
    this.labTestSubmitted = true;
    if (this.LabTestForm.invalid) {
      this.disabledBtn = false;
      return;
    }
    // if(this.LabTestForm.get('report_type').value !== 'Culture' || this.LabTestForm.get('report_type').value !== 'Culture-1') {
    //   // if(this.labParametersArray.value[0].parameter_code !== '' || this.labParametersArray.value[0].parameter_code !== null)
    //   // {
    //   for (let i = 0; i < this.labParametersArray.value.length; i++) {
    //     if (this.labParametersArray.value[i].sort_order === null || this.labParametersArray.value[i].sort_order === '') {
    //       toastr.error('Add Sort order');
    //       return;
    //     }
    //   }
    //   if (this.checkIfArrayIsUnique(this.labParametersArray.value) === false) {
    //     toastr.error('Sort order can be unique');
    //     return;
    //   }
    //   // }
    // }
    // if(this.labParametersArray.value[0].sort_order === '')
    // {
    //   this.labParametersArray.removeAt(0)
    // }
    const labTestFormValue = this.LabTestForm.value;
    labTestFormValue.cost_details = this.updateCostingDetail;
    this.ngxLoader.start();
    this.labTestService.updateV2(labTestFormValue, this.updateId).subscribe(
      (value) => {
        this.ngxLoader.stop();
        toastr.success('Successfully updated lab test');
        this.updatedData.emit({ data: value, check: false });
        this.addLabTest = true;
        this.disabledBtn = false;
        this.clearModalValues();
        setTimeout(() => {
          $('#copyParameter').selectpicker();
          $('#copyParameter').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentsByHospitalService.fetchSubDeptbyServicev2('lab', '').subscribe(
      (data) => {
        this.departments = data.departments;
        setTimeout(() => {
          $('#departmentPicker').selectpicker('refresh');
          $('#departmentPicker').selectpicker();
        }, 1000);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEhmList(): any {
    this.ngxLoader.start();
    this.ehmService.indexV2().subscribe(
      (data) => {
        this.ehmList = data.send_outs;
        setTimeout(() => {
          $('#sendout').selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $('#sendout').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  fetchLabConfigurators(): any {
    this.ngxLoader.start();
    this.labService
      .getLabSpecificConfiguratorV2('specimen', 'true', '', '', '')
      .subscribe(
        (data) => {
          this.labConfigurations = data.lab_configurations;
          setTimeout(() => {
            $('#specimen').selectpicker('refresh');
            $('#groupName').selectpicker('refresh');
          }, 500);
          setTimeout(() => {
            $('#specimen').selectpicker();
          }, 500);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getPatientTypes(): any {
    this.patientTypes = [];
    this.ngxLoader.start();
    this.patientTypeService.indexV2().subscribe(
      (data) => {
        this.patientTypes = data.patient_types;
        if (this.patientTypes && !this.updateId) {
          const defaultPatientType = this.patientTypes.find(
            (t) => t.is_default,
          );
          const patientType = defaultPatientType ? [defaultPatientType.id] : [];
          if (patientType) {
            this.LabTestForm.get('replicate_service_pat_types').setValue(
              patientType,
            );
            console.log(
              'value',
              this.LabTestForm.get('replicate_service_pat_types').value,
            );
            setTimeout(() => {
              $('#patient_type').selectpicker('refresh');
              $('#patient_type').selectpicker();
            }, 500);
          } else {
            this.LabTestForm.get('replicate_service_pat_types').setValue('');
            setTimeout(() => {
              $('#patient_type').selectpicker('refresh');
              $('#patient_type').selectpicker();
            }, 500);
          }
        } else {
          setTimeout(() => {
            $('#patient_type').selectpicker('refresh');
            $('#patient_type').selectpicker();
          }, 500);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  clearModalValues(): any {
    this.LabTestForm.reset();
    this.labTestSubmitted = false;
    this.addLabTest = true;
    this.LabTestForm.patchValue({
      profile_name: '',
      profile_code: '',
      department_id: '',
      report_type: 'Customized',
      status: true,
      color: '#000000',
      // replicate_service_pat_types: '',
      service_price: '',
      lab_code: '',
      send_out_id: '',
      specimen_id: '',
      reporting_time: false,
    });
    if (this.patientTypes) {
      const defaultPatientType = this.patientTypes.find((t) => t.is_default);
      const patientType = defaultPatientType ? [defaultPatientType.id] : [];
      this.LabTestForm.get('replicate_service_pat_types').setValue(patientType);
      setTimeout(() => {
        $('#patient_type').selectpicker('refresh');
        $('#patient_type').selectpicker();
      }, 500);
    } else {
      this.getPatientTypes();
    }
    setTimeout(() => {
      $('#departmentPicker').selectpicker('refresh');
      $('#departmentPicker').selectpicker();
      $('#sendout').selectpicker('refresh');
      $('#sendout').selectpicker();
      $('#specimen').selectpicker('refresh');
      $('#specimen').selectpicker();
    }, 500);
  }
  get f(): any {
    return this.LabTestForm.controls;
  }
  getLabDataOfSingleId(id): any {
    this.showCostingDetail = false;
    this.labService.getLabData(id).subscribe(
      (data) => {
        this.labData = data;
        // this.sharedService.sendUpdateLabDataForCosting(this.labData);
        this.labCosting = this.labData.test_cost_details;
        if (this.labData) {
          this.addLabTest = false;
          this.LabTestForm.patchValue({
            color: this.labData.color,
            department_id: this.labData.department_id,
            report_type: this.labData.report_type || 'Customized',
            send_out_id: this.labData.send_out_id || '',
            profile_name: this.labData.profile_name,
            profile_code: this.labData.profile_code,
            lab_test_group: this.labData.lab_test_group,
            lab_code: this.labData.lab_code,
            specimen_id: this.labData.specimen_id,
            status: this.labData.status,
            service_price: this.labData.service_price,
            replicate_service_pat_types:
              this.labData.replicate_service_pat_types,
            reporting_time: this.labData.reporting_time,
          });
          setTimeout(() => {
            $('.selectpicker').selectpicker();
          }, 200);
          //     for (let i = 0; i < value.parametersArray.length; i++) {
          //       this.labParametersArray.push(this.setLabParameters(value.parametersArray[i]));
          // //      this.labParametersArray.push(this.setLabParameters(this.addLabParameterGroup));
          //
          //     }
          this.labTestSubmitted = true;
          // this.IsPreviouslyEdit = true;
          setTimeout(() => {
            $('#departmentPicker').selectpicker('refresh');
            $('#departmentPicker').selectpicker();
            $('#sendout').selectpicker('refresh');
            $('#sendout').selectpicker();
            $('#specimen').selectpicker('refresh');
            $('#specimen').selectpicker();
            $('#patient_type').selectpicker('refresh');
            $('#patient_type').selectpicker();
            $('#groupName').selectpicker('refresh');
            this.showCostingDetail = true;
          }, 500);
        }
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  ngOnInit(): void {
    this.fetchDepartments();
    this.getEhmList();
    this.fetchLabConfigurators();
    this.getPatientTypes();
    this.sharedService.updateLabsData.subscribe((id) => {
      if (id) {
        this.LabTestForm.get('service_price').clearValidators();
        this.LabTestForm.get('service_price').updateValueAndValidity();
        this.LabTestForm.get('replicate_service_pat_types').clearValidators();
        this.LabTestForm.get(
          'replicate_service_pat_types',
        ).updateValueAndValidity();
        this.getLabDataOfSingleId(id);
        // if (this.labParametersArray.value[0].parameter_code === '' || this.labParametersArray.value[0].parameter_code === null) {
        //   this.labParametersArray.removeAt(0);
        // }
        this.updateId = id;
      } else {
        this.showCostingDetail = false;
        this.updateId = '';
        this.LabTestForm.get('service_price').setValidators(
          Validators.required,
        );
        this.LabTestForm.get('service_price').updateValueAndValidity();
        this.LabTestForm.get('replicate_service_pat_types').setValidators(
          Validators.required,
        );
        this.LabTestForm.get(
          'replicate_service_pat_types',
        ).updateValueAndValidity();
        this.labCosting = '';
        setTimeout(() => {
          this.showCostingDetail = true;
        }, 500);
        this.clearModalValues();
      }
    });
    setTimeout(() => {
      $('#groupName').selectpicker('refresh');
    }, 500);
  }
}
