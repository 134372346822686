<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1 w-100">
        <!--begin::Page Heading-->
        <div
          class="d-flex align-items-baseline flex-wrap mr-5 w-100 justify-content-between"
        >
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Task List</h5>
          <div>
            <button
              class="btn btn-light-primary btn-sm ml-2"
              (click)="openSearchList()"
            >
              Search Task
            </button>
            <button
              class="btn btn-light-primary btn-sm ml-2"
              (click)="openModal('')"
            >
              Add New
            </button>
          </div>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <div class="card card-custom mb-6">
        <div class="card-body">
          <form [formGroup]="searchTaskFiltersForm" (ngSubmit)="onSubmit(true)">
            <!--begin::Content-->
            <!--begin::Entry-->
            <div class="row">
                <!--Ticket ID-->
                <div class="col-2">
                  <div class="form-group">
                    <label>Ticket ID</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Ticket ID"
                      formControlName="task_id"
                      (keypress)="_onlyNumeric($event)"
                    />
                  </div>
                </div>
                <!--Name-->
              <div class="col-3">
                <div class="form-group">
                  <label>Title</label>
                  <div class="input-icon">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      formControlName="title"
                      placeholder="Name"
                    />
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
                <!--Status-->
                <div class="col-2">
                  <div class="form-group">
                    <label>Status </label>
                    <div
                    >
                      <select
                        class="form-control form-control-sm bootstrap-select-container"
                        data-live-search="true"
                        formControlName="status"
                        id="mainStatusId"
                        multiple
                        name="status"
                        data-none-selected-text="Select"
                      >
                        <option value="" [disabled]="true">
                          Select Status
                        </option>
                        <ng-container *ngFor="let status of taskStatus">
                          <option [value]="status.id">
                            {{ status?.name }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <!--Assigned To-->
                <div class="col-3">
                  <div class="form-group">
                    <label>Assigned To</label>
                    <select
                      class="form-control form-control-sm"
                      data-live-search="true"
                      formControlName="assign_to"
                      name="employee"
                      id="assigned_to"
                      data-none-selected-text="Select"
                      multiple
                    >
                      <option value="" [disabled]="true">
                        Select Employee
                      </option>
                      <ng-container *ngFor="let emp of employee">
                        <option [value]="emp.id">
                          {{ emp?.employee_file_no }} -
                          {{ emp?.first_name }}
                          {{ emp?.middle_name || "" }}
                          {{ emp?.last_name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <!--Priority-->
                <div class="col-2">
                  <div class="form-group">
                    <label>Priority </label>
                    <select
                      class="form-control form-control-sm bootstrap-select-container"
                      formControlName="priority"
                      multiple
                      id="priorityId"
                      data-none-selected-text="Select"
                    >
                      <option value="" [disabled]="true">Select Priority</option>
                      <ng-container *ngFor="let p of priority">
                        <option [value]="p.id">{{ p?.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <!--end::Card-->
            </div>
            <div class="row">
              <!--submit button-->
              <div class="col-12 text-right">
                <div>
                  <div class="form-group">
                    <div class="text-right mt-3">
                      <button class="btn btn-sm btn-primary" type="submit">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--begin:: Content-->
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div>
            <div class="">
              <table
                class="table table-head-custom table-responsive-sm table-vertical-center"
                id="kt_advance_table_widget_4"
              >
                <thead>
                <tr>
                  <th style="width: 8%">Ticket ID</th>
                  <th style="width: 20%">Title</th>
                  <th style="width: 9%">
                    Status
                    <a type="button" class="d-print-none" data-toggle="dropdown">
                      <a style="line-height: 1px; position: relative; top: 3px"
                      ><i class="fas fa-angle-down"></i
                      ></a>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                    >
                      <button
                        class="dropdown-item cursor-pointer"
                        (click)="sortStatus('asc')"
                      >
                        Ascending
                      </button>
                      <button
                        class="dropdown-item cursor-pointer"
                        (click)="sortStatus('desc')"
                      >
                        Descending
                      </button>
                    </div>
                  </th>
                  <th style="width: 10%">
                    Priority
                    <a type="button" class="d-print-none" data-toggle="dropdown">
                      <a style="line-height: 1px; position: relative; top: 3px"
                      ><i class="fas fa-angle-down"></i
                      ></a>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                    >
                      <button
                        class="dropdown-item cursor-pointer"
                        (click)="sortPriority('asc')"
                      >Ascending</button>
                      <button
                        class="dropdown-item cursor-pointer"
                        (click)="sortPriority('desc')"
                      >Descending</button>
                    </div>
                  </th>
                  <th style="width: 15%">Owner Name/Contact</th>
                  <th style="width: 27%">Assign to</th>
                  <th class="text-right" style="width: 10%">Action</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let index of pageDataList">
                  <tr>
                    <td>{{index?.id}}</td>
                    <td>
                        <span (click)="openModal(index.id)"
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                        >{{ index.name }}</span
                        >
                      <span
                        class="text-muted font-weight-bold text-muted d-block"
                      >{{ index?.code }}</span
                      >
                    </td>
                    <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <span
                            class="label label-lg label-inline"
                            [ngStyle]="{'background-color': index.task_status.color, 'color': '#FFFFFF'}"
                          >{{ index.task_status.name }}</span
                          >
                        </span>
                    </td>
                    <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <span
                            class="label label-lg label-light-green label-inline"
                          >{{ this.index.priority.name }}</span
                          >
                        </span>
                    </td>
                    <td>
                      <span> {{ index?.owner?.first_name + ' ' + index?.owner?.last_name }}</span>
                      <span class="d-block"> {{ index?.owner?.contact_no }}</span>
                    </td>
                    <td style="padding-top: 17px">
                      <ng-container *ngFor="let task of index?.task_assign">
                        <p style="line-height: 4px" class="">
                            <span class="text-dark-75 font-weight-bold">{{
                                task?.name.name
                              }}</span>
                          <span
                            style="font-size: 10px"
                            class="ml-2 text-dark-75"
                          >{{ task?.start_date | date: "dd-MM-yyyy" }}</span
                          >
                          <span
                            style="font-size: 10px"
                            class="text-dark-75 font-weight-bold ml-1"
                          >to</span
                          >
                          <span
                            style="font-size: 10px"
                            class="ml-1 text-dark-75"
                          >{{ task?.end_date | date: "dd-MM-yyyy" }}</span
                          >
                          <span *ngIf="task?.allocated_time"
                            style="font-size: 10px"
                            class="ml-1 text-dark-75 font-weight-bold"
                          >({{ task?.allocated_time + ' ' + task?.allocated_time_unit}})</span
                          >
                        </p>
                      </ng-container>
                    </td>
                    <td class="text-right">
                      <div
                        class="dropdown dropdown-inline"
                      >
                        <button
                          type="button"
                          class="btn btn-light-primary btn-icon btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="ki ki-bold-more-ver"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item cursor-pointer"
                            (click)="openModal(index.id)"
                          >Edit</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <!--pagination-->
            <div class="pagination_main">
              <ngb-pagination
                [(page)]="page"
                (pageChange)="loadPage(page)"
                [pageSize]="perPage"
                [collectionSize]="totalPages"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              ></ngb-pagination>
              <div class="dropdown">
                <a
                  class="btn dropdown-toggle btn-light btn-sm"
                  role="button"
                  id="pagination_btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="page_count">
                    {{ perPageValue }}
                  </span>
                  <span class="count_count_icon"></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="pagination_btn">
                  <a class="dropdown-item" (click)="selectPaginationLimit(25)"
                  >25</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(50)"
                  >50</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(75)"
                  >75</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit(100)"
                  >100</a
                  >
                  <a class="dropdown-item" (click)="selectPaginationLimit('')"
                  >All</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>

<!--begin::Quick Cart-->
<div
  id="search-task"
  class="offcanvas offcanvas-right p-5"
  style="overflow: auto"
>
  <!--begin::Header-->
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Search Task</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closeSeacrhList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>

  </div>
  <!--begin::Content-->
  <div class="offcanvas-content ot-patients">
    <hr
      class="w-100 line-hide"
      style="margin-bottom: 20px !important"
    />
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <form [formGroup]="searchTaskForm" (ngSubmit)="onSubmit(false)">
        <!--begin::Content-->
        <!--begin::Entry-->
        <div class="flex-column-fluid main-content">
          <!--begin::Container-->
          <!--begin::Card-->
          <div class="">
            <!--Name-->
            <div class="col-12">
              <div class="form-group">
                <label>Title</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Name"
                  formControlName="title"
                />
              </div>
            </div>
              <!--Status-->
              <div class="col-12">
                <div class="form-group">
                  <label>Status </label>
                  <div
                  >
                    <select
                      class="form-control form-control-sm selectpicker bootstrap-select-container"
                      data-live-search="true"
                      formControlName="status"
                      multiple
                      name="status"
                      data-none-selected-text="Select"
                    >
                      <option value="" [disabled]="true">
                        Select Status
                      </option>
                      <ng-container *ngFor="let status of taskStatus">
                        <option [value]="status.id">
                          {{ status?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--Type-->
              <div class="col-12">
                <div class="form-group">
                  <label>Type </label>
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    formControlName="type"
                    id="type"
                    multiple
                    data-none-selected-text="Select"
                  >
                    <option value="" [disabled]="true">Select</option>
                    <option value="new feature">New feature</option>
                    <option value="enhancement">Enhancement</option>
                    <option value="bug">Bug</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <!--Priority-->
              <div class="col-12">
                <div class="form-group">
                  <label>Priority </label>
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    formControlName="priority"
                    multiple
                    id="priority"
                    data-none-selected-text="Select"
                  >
                    <option value="" [disabled]="true">Select Priority</option>
                    <ng-container *ngFor="let p of priority">
                      <option [value]="p.id">{{ p?.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--Location-->
              <div class="col-12">
                <div class="form-group">
                  <label>Location </label>
                  <div
                  >
                    <select
                      class="form-control form-control-sm selectpicker bootstrap-select-container"
                      data-live-search="true"
                      id="selectedLocation"
                      formControlName="location"
                      name="selectedLocation"
                      multiple
                      data-none-selected-text="Select"
                    >
                      <option value="" [disabled]="true">
                        Select Location
                      </option>
                      <ng-container *ngFor="let location of locationData">
                        <option [value]="location.id">
                          {{ location?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--project-->
              <div class="col-12">
                <div class="form-group">
                  <label>Project </label>
                  <div
                  >
                    <select
                      class="form-control form-control-sm selectpicker bootstrap-select-container"
                      data-live-search="true"
                      id="addProject"
                      formControlName="project"
                      name="modalSelect"
                      multiple
                      data-none-selected-text="Select"
                    >
                      <option value="" [disabled]="true">Select Project</option>
                      <ng-container *ngFor="let p of project">
                        <option [value]="p.id">
                          {{ p?.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--Tag-->
              <div class="col-12">
                <div class="form-group tagsInput">
                  <div class="d-flex justify-content-between">
                    <div>
                      <label
                      >Tag</label
                      >
                    </div>
                  </div>
                  <select
                    class="form-control form-control-sm select2"
                    multiple="multiple"
                    id="selectTags"
                  >
                    <ng-container *ngFor="let tag of tagListData">
                      <option [value]="tag.id" *ngIf="tag.is_removed">
                        {{ tag.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--Related To-->
              <div class="col-12">
                <div class="form-group">
                  <label>Related to </label>
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    formControlName="related_to"
                    multiple
                    id="related_to"
                    data-none-selected-text="Select"
                  >
                    <option value="hims related">HIMS Related</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>
              <!--Assigned To-->
            <div class="col-12">
              <div class="form-group">
                <label>Assigned To</label>
                <select
                  class="form-control form-control-sm"
                  data-live-search="true"
                  formControlName="assign_to"
                  name="employee"
                  id="assign_to"
                  data-none-selected-text="Select"
                  multiple
                >
                  <option value="" [disabled]="true">
                    Select Employee
                  </option>
                  <ng-container *ngFor="let emp of employee">
                    <option [value]="emp.id">
                      {{ emp?.employee_file_no }} -
                      {{ emp?.first_name }}
                      {{ emp?.middle_name || "" }}
                      {{ emp?.last_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
              <!--Department-->
              <div class="col-12">
                <div class="form-group">
                  <label>Department</label>
                  <div
                  >
                    <select
                      class="form-control form-control-sm selectpicker bootstrap-select-container"
                      data-live-search="true"
                      id="department"
                      name="department"
                      formControlName="department"
                      multiple
                      data-none-selected-text="Select"
                    >
                      <option value="" [disabled]="true">
                        Select Department
                      </option>
                      <ng-container *ngFor="let dep of depart">
                        <option [value]="dep.id">{{ dep.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <!--Screen/report-->
              <div class="col-12">
                <div
                  class="form-group"
                >
                  <label>Screen/report </label>
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    formControlName="screen"
                    data-live-search="true"
                    id="screen_id"
                    multiple
                    data-none-selected-text="Select"
                  >
                    <option value="" [disabled]="true">Select</option>
                    <ng-container *ngFor="let screen of screens">
                      <option [value]="screen.id">
                        {{ screen.name }} - {{ screen?.url }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <!--submit button-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="mt-4 cursor-pointer"
                       style="color: #3699FF; font-size: 14px;"
                       (click)="resetSelectedData()"
                    ><strong>Reset</strong>
                    </p>
                  </div>
                  <div class="form-group">
                    <div class="text-right mt-3">
                      <button class="btn btn-sm btn-primary" type="submit">
                        Search Task
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <!--end::Card-->
        </div>
      </form>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Cart-->


<div
  class="modal fade main-modal"
  id="viewTaskModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
  [ngClass]="{ 'scroll-active': isMainModalOpen }"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="!taskId" class="modal-title">Add Task</h5>
        <h5 *ngIf="taskId" class="modal-title">Update Task</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeAddTaskModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container  *ngIf="openAddTaskModal">
        <div class="modal-body">
          <app-view-task
            (newStatusEvent)="addTask($event)"
            [taskId]="taskId"
          ></app-view-task>
        </div>
      </ng-container>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
