import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlascoService } from '../../../services/glasco.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RouteShareService } from '../../../services/route-share.service';
import { TaskManagementService } from '../../../services/task-management.service';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-task-tab',
  templateUrl: './task-tab.component.html',
  styleUrls: ['./task-tab.component.css'],
})
export class TaskTabComponent implements OnInit {
  taskTab: FormGroup;
  subType: FormGroup;
  typeList: any;
  subTypeId: any;
  subTypeList: any;
  showUpdateType = false;
  isAddType = false;
  isAddSubType = false;
  showUpdateSubType = false;
  typeId: '';
  screenName = '';
  constructor(
    private fb: FormBuilder,
    private taksServices: TaskManagementService,
    private ngxLoader: NgxUiLoaderService,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  ngOnInit(): void {
    $('#addTypeModal').on('hide.bs.modal', () => {
      this.taskTab.reset({
        name: '',
        is_removed: false,
      });
    });
    this.taskTab = this.fb.group({
      name: ['', Validators.required],
      is_removed: false,
    });
    this.getTagList();
  }
  addTags(): any {
    this.showUpdateType = false;
    $('#addTypeModal').modal('show');
  }
  addSubTypes(): any {
    this.showUpdateSubType = false;
    $('#addSubTypeModal').modal('show');
  }
  getTagUpdate(id, index, data): any {
    this.typeId = id;
    this.showUpdateType = true;
    this.ngxLoader.start();
    this.taskTab.patchValue({
      name: data.name,
      is_removed: data.is_removed,
    });

    this.ngxLoader.stop();
    $('#addTypeModal').modal('show');
  }

  submitType(): any {
    this.isAddType = true;
    if (this.taskTab.invalid) {
      return;
    }

    if (this.showUpdateType === false) {
      this.taksServices.addTag(this.taskTab.value).subscribe(
        (data) => {
          this.isAddType = false;
          $('#addTypeModal').modal('hide');
          this.taskTab.reset({
            name: '',
            is_removed: false,
          });
          this.ngxLoader.stop();
          this.getTagList();
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
      $('#addTypeModal').modal('hide');
    } else {
      this.taksServices.updateTag(this.taskTab.value, this.typeId).subscribe(
        (data) => {
          this.isAddType = false;
          console.log(this.typeId);
          $('#addTypeModal').modal('hide');
          this.taskTab.reset({
            name: '',
            is_removed: false,
          });
          this.showUpdateType = false;
          this.getTagList();
          this.ngxLoader.stop();
        },
        (err) => {
          console.log('err', err);
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
    }
    this.getTagList();
  }

  getTagList(): any {
    this.taksServices.getTagList().subscribe(
      (data) => {
        this.typeList = data;
        console.log(this.typeList);
      },
      (err) => {
        console.log('err', err);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
