import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { ActivatedRoute } from '@angular/router';
import { HospitalService } from '../../../services/hospital.service';
import * as moment from "moment";

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-patient-medicine-detail-v2',
  templateUrl: './patient-medicine-detail-v2.component.html',
  styleUrls: ['./patient-medicine-detail-v2.component.css'],
})
export class PatientMedicineDetailV2Component implements OnInit {
  patients: any;
  PatientSearchForm: FormGroup;
  paitentMedicineHistory = [];
  patientAllHistory = false;
  mrn = '';
  medicineId: any;
  visitID: any;
  settingData: any;
  currentDate = moment(new Date()).format('DD-MM-yyyy hh:mm A');
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private hospitalService: HospitalService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.mrn = params['mrn'];
      this.medicineId = params['items_id'];
    });
    console.log('this.medicineId', this.medicineId);
    // this.mrn = this.route.snapshot.queryParamMap.get('mrn');
    // const ids = this.route.snapshot.queryParamMap.get('id');
  }
  fetchPatientInfo(): any {
    this.ngxLoader.start();
    this.patientService
      .getPatientMedicines(this.mrn, this.medicineId)
      .subscribe(
        (data) => {
          if (data) {
            this.patients = data.patient;
            this.paitentMedicineHistory = data.data;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetailsv2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  ngOnInit(): void {
    this.fetchPatientInfo();
    this.getSettingsData();
  }
}
