import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../services/patient.service';
import { OtIssueItemsService } from '../../../services/ot-issue-items.service';
import { UserService } from '../../../services/user.service';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-issue-item-v2',
  templateUrl: './issue-item-v2.component.html',
  styleUrls: ['./issue-item-v2.component.css'],
})
export class IssueItemV2Component implements OnInit {
  PatientSearchForm: FormGroup;
  issueItemsForm: FormGroup;
  returnItemsForm: FormGroup;
  patient: any;
  patientAdmissions = [];
  issueItems = [];
  patientIssuedItems = [];
  isSubmit = false;
  currentProductData: any;
  totalAmount = 0;
  // screen controls
  screenControls = [];
  isShowIssueItemCard = false;
  isShowReturnCol = false;
  screenName = '';
  mrn = '';

  constructor(
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private otIssueService: OtIssueItemsService,
    private router: Router,
    private userService: UserService,
    private routeSevice: RouteShareService,
    private route: ActivatedRoute,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.mrn = this.route.snapshot.queryParamMap.get('id');
  }
  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('id')) {
      this.PatientSearchForm = this.fb.group({
        search_query: [this.route.snapshot.queryParamMap.get('id')],
        type: ['mrn'],
      });
    } else {
      this.PatientSearchForm = this.fb.group({
        search_query: [''],
        type: ['default'],
      });
    }
    this.issueItemsForm = this.fb.group({
      patient_id: ['', Validators.required],
      admission_id: ['', Validators.required],
      visit_id: ['', Validators.required],
      req_issue_to_ward: [''],
      req_gen_from_ward: [''],
      issueItemsArray: this.fb.array([]),
    });
    this.returnItemsForm = this.fb.group({
      returnItemsArray: this.fb.array([]),
    });
    this.getScreenControls();
    this.fetchPatients();
    this.getOTProducts();
  }
  // screen controls
  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        this.updateItemsCardCheck();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  updateItemsCardCheck(): any {
    if (this.screenControls.length > 0) {
      if (
        this.screenControls.filter((m) => m.code === 'IssueItem').length > 0
      ) {
        this.isShowIssueItemCard = this.screenControls.filter(
          (m) => m.code === 'IssueItem',
        )[0].is_show;
      }
      if (
        this.screenControls.filter((m) => m.code === 'ReturnItem').length > 0
      ) {
        this.isShowReturnCol = this.screenControls.filter(
          (m) => m.code === 'ReturnItem',
        )[0].is_show;
      }
    }
  }
  fetchPatients(): any {
    this.ngxLoader.start();
    const referal = false;
    this.patientService
      .searchFinalSettlementPatientFromIssueItemsV2(
        this.PatientSearchForm.value,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patientAdmissions = data.patients;
          if (this.route.snapshot.queryParamMap.get('id')) {
            this.selectPatient(this.patientAdmissions[0]);
          }
        },
        (err) => {
          this.patientAdmissions = [];
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  getOTProducts(): any {
    this.issueItems = [];
    this.ngxLoader.start();
    this.otIssueService.otStoreProducts().subscribe(
      (data) => {
        this.issueItems = data;
        if (this.issueItems) {
          setTimeout(() => {
            $('.selectpicker').selectpicker('refresh');
          }, 200);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getOTProductDetail(id, i): any {
    let count = 0;
    for (let j = 0; j < this.controlIssueItems.length; j++) {
      if (Number(id) === Number(this.controlIssueItems.at(j).get('id').value)) {
        count++;
      }
    }
    if (count > 1) {
      toastr.error('Duplicate Entry');
      // this.controlIssueItems.at(i).get('id').patchValue('');
      this.controlIssueItems.at(i).patchValue({
        id: '',
        item_code: '',
        item_name: '',
        uom: '',
        available_qty: '',
        issue_qty: 0,
        sale_price: '',
        amount: 0,
      });
      return;
    }
    this.ngxLoader.start();
    this.otIssueService.otStoreProductDetail(Number(id)).subscribe(
      (data) => {
        this.currentProductData = data;
        if (this.currentProductData) {
          this.patchProductData(i);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        this.controlIssueItems.at(i).patchValue({
          id: '',
          item_code: '',
          item_name: '',
          uom: '',
          available_qty: '',
          issue_qty: 0,
          sale_price: '',
          amount: 0,
        });
      },
    );
  }
  patchProductData(i): any {
    this.controlIssueItems.at(i).patchValue({
      item_code: this.currentProductData.code,
      item_name: this.currentProductData.name,
      uom: this.currentProductData.uom,
      available_qty: this.currentProductData.stock_qty,
      sale_price: this.currentProductData.sale_price,
      issue_qty: 0,
      amount: 0,
    });
  }
  updatePrice(value: any, i: number): any {
    if (this.controlIssueItems.at(i).get('issue_qty').value > this.controlIssueItems.at(i).get('available_qty').value){
      this.controlIssueItems.at(i).get('issue_qty').setValue(this.controlIssueItems.at(i).get('available_qty').value);
      return;
    }
    const quantity = Number(value);
    this.controlIssueItems
      .at(i)
      .get('amount')
      .patchValue(
        (
          quantity * this.controlIssueItems.at(i).get('sale_price').value
        ).toFixed(2),
      );
  }
  fetchPatientsByMrn(): any {
    this.ngxLoader.start();
    const referal = false;
    this.patientService
      .searchFinalSettlementPatientFromIssueItems(this.PatientSearchForm.value)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patientAdmissions = data.patients;
          this.selectPatient(this.patientAdmissions[0]);
        },
        (err) => {
          this.patientAdmissions = [];
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPatient(data): any {
    while (this.controlIssueItems.length) {
      this.controlIssueItems.removeAt(0);
    }
    while (this.controlReturnItems.length) {
      this.controlReturnItems.removeAt(0);
    }
    this.patient = data;
    this.closePatientList();
    if (this.patient) {
      this.getPatientIssuedItems(this.patient.admissions[0].id);
    }
    if (this.isShowIssueItemCard === false) {
      return;
    }
    this.addMoreIssueItems();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 100);
  }
  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }
  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }
  createIssueItems(): any {
    return this.fb.group({
      id: [''],
      item_code: [''],
      item_name: ['', Validators.required],
      uom: ['', Validators.required],
      available_qty: ['', Validators.required],
      issue_qty: [0, Validators.required],
      sale_price: ['', Validators.required],
      amount: [0, Validators.required],
    });
  }
  get controlIssueItems(): any {
    return this.issueItemsForm.get('issueItemsArray') as FormArray;
  }
  addMoreIssueItems(): any {
    this.controlIssueItems.push(this.createIssueItems());
    this.isSubmit = false;
    setTimeout(() => {
      $('.selectpicker').selectpicker('refresh');
    }, 100);
  }
  removeIssueItems(i: number): any {
    this.controlIssueItems.removeAt(i);
  }
  getPatientIssuedItems(admissionId): any {
    while (this.controlReturnItems.length) {
      this.controlReturnItems.removeAt(0);
    }
    this.patientIssuedItems = [];
    if (!admissionId) {
      toastr.error('Select Patient');
      return;
    }
    this.ngxLoader.start();
    this.otIssueService.getIssuedItems(admissionId).subscribe(
      (data) => {
        this.patientIssuedItems = data;
        if (data) {
          for (let i = 0; i < data.length; i++) {
            this.controlReturnItems.push(this.addReturnItems(data[i]));
          }
          this.calculateTotalAmount();
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  submit(): any {
    this.issueItemsForm.get('patient_id').patchValue(this.patient.id);
    this.issueItemsForm.get('visit_id').patchValue(this.patient.visit_id);
    this.issueItemsForm
      .get('admission_id')
      .patchValue(this.patient.admissions[0].id);
    this.issueItemsForm
      .get('req_gen_from_ward')
      .patchValue(this.patient.admissions[0]?.ward_id);
    this.issueItemsForm
      .get('req_issue_to_ward')
      .patchValue(this.patient.admissions[0]?.ward_id);
    this.isSubmit = true;
    if (this.issueItemsForm.invalid) {
      return;
    }
    if (this.controlIssueItems.length < 1) {
      toastr.error('Select Product');
      return;
    }
    for (let i = 0; i < this.controlIssueItems.length; i++) {
      if (this.controlIssueItems.at(i).get('issue_qty').value === 0) {
        toastr.error('Issue quantity must be greater than 0');
        return;
      }
    }
    this.ngxLoader.start();
    this.otIssueService.postIssueItems(this.issueItemsForm.value).subscribe(
      (data) => {
        toastr.success('Successfully Issue Item');
        this.isSubmit = false;
        while (this.controlIssueItems.length) {
          this.controlIssueItems.removeAt(0);
        }
        this.getPatientIssuedItems(this.patient.admissions[0].id);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  // Return Items Code
  addReturnItems(data): any {
    return this.fb.group({
      issue_notes: data.issue_notes,
      product: data.product.id,
      date: data.created_on,
      reference_no: data.reference_no,
      item_code: data.product.code,
      item_name: data.product.name,
      uom: data.product.uom,
      issue_quantity: data.issue_quantity,
      return_qty: data.return_qty,
      returned_qty: 0,
      balance_qty: '',
      sale_price: data.sale_price,
      amount: [''],
    });
  }
  get controlReturnItems(): any {
    return this.returnItemsForm.get('returnItemsArray') as FormArray;
  }
  // addMoreReturnItems(): any {
  //   this.controlIssueItems.push(this.createIssueItems());
  //   setTimeout( () => {
  //     $('.selectpicker').selectpicker('refresh');
  //   }, 100);
  // }
  removeReturnItems(i: number): any {
    this.controlIssueItems.removeAt(i);
  }
  checkReturnQty(value: any, i: number): any {
    if (
      Number(value) + this.controlReturnItems.at(i).get('return_qty').value >
      this.controlReturnItems.at(i).get('issue_quantity').value
    ) {
      this.controlReturnItems.at(i).get('returned_qty').patchValue(0);
    }
    setTimeout(() => {
      this.calculateTotalAmount();
    }, 200);
  }
  submitReturnItems(): any {
    let array = [];
    for (let i = 0; i < this.controlReturnItems.length; i++) {
      let obj = {
        id: '',
        product: '',
        returned_qty: '',
        issue_quantity: '',
      };
      if (this.controlReturnItems.at(i).get('returned_qty').value > 0) {
        obj = {
          id: this.controlReturnItems.at(i).get('issue_notes').value,
          product: this.controlReturnItems.at(i).get('product').value,
          returned_qty: this.controlReturnItems.at(i).get('returned_qty').value,
          issue_quantity: this.controlReturnItems.at(i).get('issue_quantity')
            .value,
        };
        array.push(obj);
      }
    }
    if (array.length === 0 || array.length < 1) {
      toastr.error('Enter return quantity');
      return;
    }
    this.ngxLoader.start();
    this.otIssueService.postReturnItems(array).subscribe(
      (data) => {
        toastr.success('Successfully Returned');
        this.getPatientIssuedItems(this.patient.admissions[0].id);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  calculateTotalAmount(): any {
    this.totalAmount = 0;
    for (let i = 0; i < this.controlReturnItems.length; i++) {
      this.totalAmount =
        this.totalAmount +
        (this.controlReturnItems.at(i).get('issue_quantity').value -
          this.controlReturnItems.at(i).get('return_qty').value -
          this.controlReturnItems.at(i).get('returned_qty').value) *
          this.controlReturnItems.at(i).get('sale_price').value;
    }
  }
  printDetail(): any {
    this.router.navigate([
      '/patient/issue-item-print/' +
        this.patient.admissions[0].id +
        '/' +
        this.patient.mrn,
    ]);
    // window.open('finance/reports/voucher/' + data.id);
  }
  printSummary(): any {
    this.router.navigate(['/patient/issue-item-print-v2'], {
      queryParams: {
        mrn: this.patient.mrn,
        admission_id: this.patient.admissions[0].id,
      },
    });
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
