import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppComponent } from '../app.component';
import { LabTestService } from '../services/lab-test.service';
import { environment } from '../../environments/environment';
import { HospitalService } from '../services/hospital.service';

declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-online-lab-report',
  templateUrl: './online-lab-report.component.html',
  styleUrls: ['./online-lab-report.component.css'],
})
export class OnlineLabReportComponent implements OnInit {
  listData: any;
  environment = environment;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  currentEnvironment: string;
  homeImage = '';
  patient: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: AuthService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private appComponent: AppComponent,
    private labTestService: LabTestService,
    private hospitalService: HospitalService,
  ) {}

  ngOnInit(): void {
    this.getSettingsData();
    this.loginForm = this.formBuilder.group({
      mrn: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(11)]],
    });
    this.currentEnvironment = environment.rorUrl;
  }

  getSettingsData(): any {
    // console.log('in1');
    this.hospitalService.getMedicalUnitHomeImage('').subscribe(
      (data) => {
        this.homeImage = data.homepage_image;
      },
      (err) => {},
    );
  }

  createSession(): any {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.userService
      .loginPatient(this.loginForm.value.mrn, this.loginForm.value.password)
      .subscribe(
        (data) => {
          this.patient = data;
          this.fetchLabsReportsTestList(data.mrn);
          this.ngxLoader.stop();
          toastr.success('Successfully Logged In.');
        },
        (err) => {
          this.ngxLoader.stop();
          console.log('....', err);
          toastr.error(err.error.message);
        },
      );
  }

  fetchLabsReportsTestList(patientId): any {
    this.ngxLoader.start();
    this.labTestService.fetchPatientLabReports(patientId).subscribe(
      (data) => {
        this.listData = data.orders;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  printReport(id, type): any {
    console.log('type', type);
    if (type === 'Radiology') {
      window.open(
        this.currentEnvironment +
          '/v4/reports/print_patient_report.pdf?id=' +
          id,
      );
    } else {
      window.open(
        this.currentEnvironment +
          '/v4/orders/online_lab_order_report.pdf?id=' +
          id,
      );
    }
  }

  get f(): any {
    return this.loginForm.controls;
  }
}
