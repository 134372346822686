import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OpdService } from '../../../../services/opd.service';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../../../services/departmentsbyhospital.service';
import { BillingCategoryService } from '../../../../services/billing-category.service';
import { OtService } from '../../../../services/ot.service';
import { EmployeeRegistrationService } from '../../../../services/employee-registration.service';
import { environment } from '../../../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-operation-request',
  templateUrl: './operation-request.component.html',
  styleUrls: ['./operation-request.component.css'],
})
export class OperationRequestComponent implements OnInit {
  @ViewChild('cancelOperationRequest') cancelOperationRequest: ElementRef;
  ngbModalStatic: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  @Input() patient: any;
  @Input() visitType: any;
  @Input() oRDoctors: any;
  @Input() isAdd: boolean;

  operationRequestForm: FormGroup;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  isSubmitoperationRequest = false;
  department = [];
  otServices = [];
  operationRooms = [];
  anesthesiaTypes = [];
  durations = [];
  urgency = [];
  allOperationsRequests = [];
  cancelRequestID: number;
  Doctors = [];
  currentDate = new Date();
  docs = [];
  idForStoringDocs: any;

  constructor(
    private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private opdService: OpdService,
    private hospitalDoctorsService: HospitaldoctorService,
    private departmentService: DepartmentsbyhospitalService,
    private billingService: BillingCategoryService,
    private otService: OtService,
    private employeeService: EmployeeRegistrationService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.operationRequestForm = this.formBuilder.group({
      advisory_doctor: ['', Validators.required],
      department: ['', Validators.required],
      ot_service: ['', Validators.required],
      anesthesia_type_id: [''],
      suggested_date: [this.today, Validators.required],
      suggested_time: ['', Validators.required],
      est_dur_value: ['', Validators.required],
      est_dur_type_id: ['', Validators.required],
      urgency_id: [''],
      comments: [''],
      operation_room: [''],
      type: [''],
    });

    this.fetchOTServices();
    // this.fetchDoctor();
    this.fetchDepartments();
    this.fetchOperationRooms();
    this.fetchAnesthesiaTypes();
    this.fetchDurations();
    this.fetchUrgency();
    this.fetchOperationRequests('');
    this.resetOperationRequestForm();
  }

  ngOnChanges(changes: SimpleChanges): any {
    this.fetchOperationRequests(changes.patient.currentValue);
  }

  fetchUrgency(): any {
    this.urgency = [];
    this.employeeService.fetchConfigurationV2('urgency').subscribe(
      (data) => {
        this.urgency = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDurations(): any {
    this.durations = [];
    this.employeeService.fetchConfigurationV2('est_dur_type').subscribe(
      (data) => {
        this.durations = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchAnesthesiaTypes(): any {
    this.anesthesiaTypes = [];
    this.employeeService.fetchConfigurationV2('anesthesia_type').subscribe(
      (data) => {
        this.anesthesiaTypes = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.indexDepartmentsv2().subscribe(
      (data) => {
        this.department = data.departments;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#otDepartment').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchOperationRooms(): any {
    this.operationRooms = [];
    this.ngxLoader.start();
    this.otService.getOperationRoomsV2().subscribe(
      (data) => {
        this.operationRooms = data.operation_rooms;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchOTServices(): any {
    this.ngxLoader.start();
    this.otServices = [];
    this.billingService.getAllOtServicesV2().subscribe(
      (data) => {
        this.otServices = data.ot_services;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#otServiceName').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  onSubmitoperationRequest(): any {
    const time = this.operationRequestForm.get('suggested_time').value;
    if (time) {
      const taken = moment(time, 'HH:mm:ss');
      const takenTime = moment(taken).utc().format('H:mm');
      this.operationRequestForm.get('suggested_time').patchValue(takenTime);
    }
    // this.consultationForm.get('patient_id').patchValue(this.patient.id);
    // this.consultationForm.get('status').patchValue('ipd');

    this.isSubmitoperationRequest = true;
    if (this.operationRequestForm.invalid) {
      return;
    }
    this.operationRequestForm.get('type').patchValue('');

    this.ngxLoader.start();
    this.opdService
      .addOperationRequestV2(this.operationRequestForm.value, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.idForStoringDocs = data.id;
          this.resetOperationRequestForm();
          this.sendDocs();
          this.fetchOperationRequests('');
          toastr.success('Operation Request Added');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchDoctorDepart(id: any): any {
    const departId = this.oRDoctors.filter((t) => t.id === Number(id))[0]
      .doctor_department_id;
    if (departId) {
      this.operationRequestForm.get('department').patchValue(departId);
    } else {
      this.operationRequestForm.get('department').patchValue('');
    }
    setTimeout(() => {
      $('#otDepartment').selectpicker('refresh');
    }, 200);
  }

  get c(): any {
    return this.operationRequestForm.controls;
  }

  resetOperationRequestForm(): any {
    this.operationRequestForm.reset({
      advisory_doctor: '',
      department: '',
      ot_service: '',
      anesthesia_type_id: '',
      suggested_date: this.today,
      suggested_time: '',
      est_dur_value: '',
      est_dur_type_id: '',
      urgency_id: '',
      comments: '',
      operation_room: '',
    });

    this.isSubmitoperationRequest = false;
    setTimeout(() => {
      $('#otAdviceBy').selectpicker('refresh');
      $('#otAdviceBy').selectpicker('');
      $('#otServiceName').selectpicker('refresh');
      $('#otServiceName').selectpicker('');
      $('#otDepartment').selectpicker('refresh');
      $('#otDepartment').selectpicker('');
    }, 500);
  }

  fetchOperationRequests(val): any {
    // console.log('va;lllllllllllllll', val);
    this.ngxLoader.start();
    this.allOperationsRequests = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getOperationRequestsV2(this.patient, this.visitType)
      .subscribe(
        (data) => {
          this.allOperationsRequests = data.operation_requests;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  fetchDepartDoctors(value: any): any {
    this.operationRequestForm.get('advisory_doctor_id').patchValue('');
    this.Doctors = [];
    if (value) {
      this.ngxLoader.start();
      this.hospitalDoctorsService
        .fetchDepartmentDoctorListV2(Number(value))
        .subscribe(
          (data) => {
            this.Doctors = data.users;
            this.ngxLoader.stop();
            setTimeout(() => {
              $('#otAdviceBy').selectpicker('refresh');
            }, 500);
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }

  printPatientoperationRequest(id): any {
    window.open(
      'pdf-reports/patient-operation-request-report?id=' +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&request_id=' +
        id,
    );
    // window.open(environment.rorUrl + '/v4/operation_requests/print_report.pdf?id=' + id + '&auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'));
  }

  cancelPatientoperationRequest(id): any {
    this.cancelRequestID = id;
    this.modalService.open(this.cancelOperationRequest, this.ngbModalStatic);
  }

  cancelRequest(): any {
    this.modalService.dismissAll(this.cancelOperationRequest);
    this.ngxLoader.start();
    this.opdService
      .cancelPrintOperationRequestV2(this.patient, this.cancelRequestID)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.fetchOperationRequests('');
          toastr.success('Request Cancelled');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  sendImages(event): any {
    this.docs = event;
  }

  sendDocs(): any {
    this.ngxLoader.start();
    this.otService
      .addDocumentInOperationRequests(this.docs, this.idForStoringDocs)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.docs = [];
        },
        (err) => {
          this.docs = [];
          this.ngxLoader.stop();
          toastr.error(err.error.error);
        },
      );
  }
}
