import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

declare var toastr: any;
@Component({
  selector: 'app-attendance-status-policy',
  templateUrl: './attendance-status-policy.component.html',
  styleUrls: ['./attendance-status-policy.component.css'],
})
export class AttendanceStatusPolicyComponent implements OnInit {
  attendancePolicy: FormGroup;
  policyId: any;
  isAdd = false;
  statusListing = [];

  constructor(
    private fb: FormBuilder,
    private ngxLoder: NgxUiLoaderService,
    private rostarService: RosterServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.attendancePolicy = this.formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      status: [true],
      attendance_rules: this.formBuilder.array([]),
    });
  }
  get attendance_rules(): any {
    return this.attendancePolicy.get('attendance_rules') as FormArray;
  }
  onPolicySubmitSubmit(): any {
    this.isAdd = true;
    if (this.attendancePolicy.invalid) {
      return;
    }
    this.isAdd = false;
    if (this.policyId) {
      this.ngxLoder.start();
      this.rostarService
        .updateAttendanceStatusPolicy(
          this.attendancePolicy.value,
          this.policyId,
        )
        .subscribe(
          (data) => {
            this.ngxLoder.stop();
            this.getAttendancePolicy();
            toastr.success('Updated Successfully');
          },
          (err) => {
            toastr.error(err.error.error);
            this.ngxLoder.stop();
          },
        );
    } else {
      this.ngxLoder.start();
      this.rostarService
        .submitAttendanceStatusPolicy(this.attendancePolicy.value)
        .subscribe(
          (data) => {
            this.ngxLoder.stop();
            this.getAttendancePolicy();
            toastr.success('Added Successfully');
          },
          (err) => {
            toastr.error(err.error.error);
            this.ngxLoder.stop();
          },
        );
    }
  }

  getAttendancePolicy(): any {
    this.ngxLoder.start();
    while (this.attendance_rules.length) {
      this.attendance_rules.removeAt(0);
    }
    this.attendancePolicy.reset();
    this.policyId = null;
    this.rostarService.getAttendanceStatusPolicy().subscribe(
      (data) => {
        this.statusListing = data.list;
        if (this.statusListing.length > 0) {
          this.policyId = this.statusListing[0].id;
          this.attendancePolicy.patchValue({
            code: this.statusListing[0].code || '',
            name: this.statusListing[0].name || '',
            description: this.statusListing[0].description || '',
            status: true,
          });
          for (
            let j = 0;
            j < this.statusListing[0].attendance_rules.length;
            j++
          ) {
            this.attendance_rules.push(
              this.addAttendanceRules(
                this.statusListing[0].attendance_rules[j],
              ),
            );
          }
        } else {
          this.attendance_rules.push(this.setAttendanceRules());
        }

        this.ngxLoder.stop();
      },
      (err) => {
        toastr.error(err.error.error);
        this.ngxLoder.stop();
      },
    );
  }
  addMore(): any {
    this.attendance_rules.push(this.setAttendanceRules());
  }
  setAttendanceRules(): any {
    return this.formBuilder.group({
      check_in_status: ['', Validators.required],
      check_out_status: ['', Validators.required],
      status: ['', Validators.required],
    });
  }
  addAttendanceRules(data): any {
    return this.formBuilder.group({
      check_in_status: [data.check_in_status, Validators.required],
      check_out_status: [data.check_out_status, Validators.required],
      status: [data.status, Validators.required],
    });
  }
  removePolicy(ind): any {
    if (this.attendance_rules.length > 1) {
      this.attendance_rules.removeAt(ind);
    }
  }
  ngOnInit(): void {
    this.getAttendancePolicy();
  }
}
