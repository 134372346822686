import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DepartmentService } from '../../../services/department.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EmployeeRegistrationService } from '../../../services/employee-registration.service';
import { DoctorConfigurationService } from '../../../services/doctor-configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-pathologist-remarks',
  templateUrl: './pathologist-remarks.component.html',
  styleUrls: ['./pathologist-remarks.component.css'],
})
export class PathologistRemarksComponent implements OnInit {
  @ViewChild('addNewDepartment') addNewDepartment: ElementRef;
  @ViewChild('addNewSubDepartment') addNewSubDepartment: ElementRef;
  @ViewChild('addpathologyRemarks') addpathologyRemarks: ElementRef;
  @ViewChild('departmentUpdateModal') departmentUpdateModal: ElementRef;
  @ViewChild('subDepartmentUpdateModal') subDepartmentUpdateModal: ElementRef;

  addDepartment = {
    doctor_package_id: '',
    title: '',
    department_code: '',
    is_active: false,
    floor: 'Basement',
  };
  departmentList = [];
  addSubDepartment = {
    title: '',
    department_code: '',
    is_active: false,
    floor: 'Basement',
    parent_id: '',
  };
  subDepartmentsList: any[] = [];
  selectedDepartment: any;

  addRemarks = {
    code: '',
    remarks: '',
    active: false,
  };
  departmentId: any;
  remarksList = [];
  updateRemarks: FormGroup;
  submitted = false;
  doctorCategories = [];
  doctorPackages = [];
  activeDepartment: any;
  activeSubDepartment: any;
  constructor(
    private departmentService: DepartmentService,
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private employeeService: EmployeeRegistrationService,
    private doctorConfigService: DoctorConfigurationService,
    private modalService: NgbModal,
    private departmentsByHospitalService: DepartmentsbyhospitalService,
  ) {
    this.updateRemarks = this.formBuilder.group({
      remarks: this.formBuilder.array([]),
    });
  }
  ngOnInit() {
    // this.getDepartmentList();

    this.fetchDepartments();
    this.getDoctorPackages();
  }
  // getDoctorCategory() {
  //   this.ngxLoader.start();
  //   this.employeeService.getBillingCategories().subscribe(
  //     data => {
  //       this.doctorCategories = data.doctor_categories;
  //       this.ngxLoader.stop();
  //     },
  //     err => {
  //       this.ngxLoader.stop();
  //     }
  //   );
  // }

  getDoctorPackages() {
    this.ngxLoader.start();
    this.doctorConfigService.getDoctorPackages().subscribe(
      (data) => {
        this.doctorPackages = data.doctor_packages;
        this.ngxLoader.stop();
      },
      (error) => {
        this.ngxLoader.stop();
      },
    );
  }
  getDepartmentList() {
    this.ngxLoader.start();
    this.departmentService.getOverAllDepartmentList().subscribe(
      (data) => {
        this.departmentList = data.departments;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchDepartments() {
    this.ngxLoader.start();
    this.departmentsByHospitalService.fetchSubDeptbyService('lab').subscribe(
      (data) => {
        this.subDepartmentsList = data.departments;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  departmentDetail(data): any {
    this.selectedDepartment = data;
    this.subDepartmentsList = this.selectedDepartment.sub_departments;
  }
  submitSubDepartmentFun(): any {
    $('#subDepartmentName, #subDepartmentCode').css('border', '1px solid #ccc');
    if (this.addSubDepartment.department_code === '') {
      $('#subDepartmentCode').css('border', '1px solid #dc3545');
      return;
    }
    if (this.addSubDepartment.title === '') {
      $('#subDepartmentName').css('border', '1px solid #dc3545');
      return;
    }

    if (
      this.selectedDepartment === 'undefined' ||
      this.selectedDepartment === 'null' ||
      !this.selectedDepartment
    ) {
      toastr.error('Select Parent Department');
      return;
    }

    this.addSubDepartment.parent_id = this.selectedDepartment.id;

    if (this.addSubDepartment.parent_id === '') {
      toastr.error('Select Parent Department');
      return;
    }

    this.ngxLoader.start();
    this.departmentService.addSubDepartmentFun(this.addSubDepartment).subscribe(
      (data) => {
        this.modalService.dismissAll(this.addNewSubDepartment);
        this.addSubDepartment = {
          title: '',
          department_code: '',
          is_active: false,
          floor: 'Basement',
          parent_id: '',
        };
        this.fetchDepartments();
        // this.getDepartmentList();
        toastr.success('Sub Department Added');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  // getSubDepartmentList() {
  //   this.ngxLoader.start();
  //   this.departmentService.getSubDepartmentListFun(this.selectedDepartment).subscribe(
  //     data => {
  //       console.log(data.departments);
  //       this.subDepartmentsList = data.departments;
  //       this.ngxLoader.stop();
  //     },
  //     err => {
  //       this.ngxLoader.stop();
  //       toastr.error(err.error.message);
  //     }
  //   );
  // }

  updateSubDepartment(): any {
    const departmentData = [];
    $('.subDepartmentName').css('border', '1px solid #ccc');

    if (this.activeSubDepartment.title === '') {
      $('#updateSubDepartmentName').css('border', '1px solid #dc3545');
      return;
    }

    // for ( let i = 0; i < this.subDepartmentsList.length; i++ ) {
    //   if ( this.subDepartmentsList[i].title === '' ) {
    //     $('#updateSubDepartmentName').css('border', '1px solid #dc3545');
    //     return;
    //   }
    //   let obj = {
    //     title: this.subDepartmentsList[i].title,
    //     is_active: this.subDepartmentsList[i].is_active,
    //     floor: this.subDepartmentsList[i].floor,
    //     department_code: this.subDepartmentsList[i].department_code,
    //     id: this.subDepartmentsList[i].id,
    //     parent_id: this.selectedDepartment.id,
    //   };
    //   departmentData.push(obj);
    // }

    const obj = {
      title: this.activeSubDepartment.title,
      is_active: this.activeSubDepartment.is_active,
      floor: this.activeSubDepartment.floor,
      department_code: this.activeSubDepartment.department_code,
      id: this.activeSubDepartment.id,
      parent_id: this.selectedDepartment.id,
    };
    departmentData.push(obj);

    this.ngxLoader.start();
    this.departmentService.updateDepartment(departmentData).subscribe(
      (data) => {
        this.modalService.dismissAll(this.subDepartmentUpdateModal);
        this.activeSubDepartment = '';
        this.fetchDepartments();
        // this.getDepartmentList();
        toastr.success('Successfully Update');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  detailsPathologistRemarks(department, event): any {
    $('.lab-rows').removeClass('active');
    $(event.target).parent().addClass('active');
    // $('#pathologistRemarks').modal('show');
    this.addRemarks = {
      code: '',
      remarks: '',
      active: false,
    };
    this.departmentId = department.id;
    while (this.remarksArray.length) {
      this.remarksArray.removeAt(0);
    }
    this.getPathologistRemarks();
  }

  setremarksArray(data) {
    return this.formBuilder.group({
      id: [data.id],
      remarks: [data.remarks],
      code: [data.code],
      active: [data.active],
    });
  }
  get remarksArray() {
    return this.updateRemarks.get('remarks') as FormArray;
  }

  getPathologistRemarks() {
    this.ngxLoader.start();
    this.submitted = false;
    this.departmentService.getPathologistRemarks(this.departmentId).subscribe(
      (data) => {
        this.remarksList = data.pathologist_remarks;
        while (this.remarksArray.length) {
          this.remarksArray.removeAt(0);
        }
        for (let i = 0; i < this.remarksList.length; i++) {
          this.remarksArray.push(this.setremarksArray(this.remarksList[i]));
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  addPathologistRemarks(): any {
    if (
      !this.departmentId ||
      this.departmentId === 'undefined' ||
      this.departmentId === 'null'
    ) {
      toastr.error('Select Department');
      return;
    }

    if (this.addRemarks.code === '' || this.addRemarks.remarks === '') {
      toastr.error('Add Data');
      return;
    }
    this.ngxLoader.start();
    this.departmentService
      .addPathologistRemarks(this.addRemarks, this.departmentId)
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.addpathologyRemarks);
          this.getPathologistRemarks();
          this.addRemarks = {
            code: '',
            remarks: '',
            active: false,
          };
          toastr.success('Add Successfully');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  onUpdateRemarks() {
    console.log('updateRemarks', this.updateRemarks.value);
    this.ngxLoader.start();
    this.departmentService
      .UpdatePathologistRemarks(
        this.updateRemarks.value.remarks,
        this.departmentId,
      )
      .subscribe(
        (data) => {
          this.getPathologistRemarks();
          toastr.success('Update successfully');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  departmentUpdate(activeDepartment): any {
    this.activeDepartment = activeDepartment;
    console.log('activeDepartment', activeDepartment);
    this.modalService.open(this.departmentUpdateModal, { size: 'lg' });
  }

  addDepartModal(): any {
    this.modalService.open(this.addNewDepartment, { size: 'lg' });
  }

  closeDepartModal(): any {
    this.modalService.dismissAll(this.addNewDepartment);
    this.addDepartment = {
      doctor_package_id: '',
      title: '',
      department_code: '',
      is_active: false,
      floor: 'Basement',
    };
  }

  addSubDepartModal(): any {
    this.modalService.open(this.addNewSubDepartment, { size: 'lg' });
  }

  closeSubDepartmModal(): any {
    this.modalService.dismissAll(this.addNewSubDepartment);
    this.addSubDepartment = {
      title: '',
      department_code: '',
      is_active: false,
      floor: 'Basement',
      parent_id: '',
    };
  }

  closePathologistRemarks(): any {
    this.modalService.dismissAll(this.addpathologyRemarks);
    this.addRemarks = {
      code: '',
      remarks: '',
      active: false,
    };
  }

  addPathologistRemarksModal(): any {
    this.modalService.open(this.addpathologyRemarks, { size: 'lg' });
  }
}
