import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabconfigurationService } from '../../../services/labconfiguration.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-configuration-v2',
  templateUrl: './configuration-v2.component.html',
  styleUrls: ['./configuration-v2.component.css'],
})
export class ConfigurationV2Component implements OnInit {
  labConfigurationForm: FormGroup;
  submitted = false;
  labConfigurations = [];
  allTypes = [
    { key: 'specimen', value: 'Specimen' },
    { key: 'uom', value: 'Unit Of Measure' },
    { key: 'body_region', value: 'Body Regions' },
    { key: 'heading', value: 'Heading' },
    { key: 'sample_taken_from', value: 'Sample Taken From' },
    { key: 'lis_machine', value: 'Lab Machine' },
  ];
  activeTypes = 'specimen';
  isAddData = true;
  itemIndex: any;
  searchStatus = 'true';
  searchCode = '';
  page = 1;
  perPage = 25;
  totalPages: any;
  constructor(
    private formBuilder: FormBuilder,
    private labService: LabconfigurationService,
    private ngxLoader: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    this.labConfigurationForm = this.formBuilder.group({
      id: [''],
      code: ['', Validators.required],
      description: ['', Validators.required],
      configurator_type: ['specimen', Validators.required],
      active: ['true'],
      is_default: [false],
    });
    this.fetchLabConfigurators();
  }

  fetchLabConfigurators(): any {
    this.ngxLoader.start();
    this.labService
      .getLabSpecificConfiguratorV2(
        this.activeTypes,
        this.searchStatus,
        this.searchCode,
        this.page,
        this.perPage,
      )
      .subscribe(
        (data) => {
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.lab_configurations) {
            this.labConfigurations = data.lab_configurations;
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  addNewItem() {
    this.submitted = false;
    this.isAddData = true;
    this.labConfigurationForm.reset({
      id: '',
      code: '',
      description: '',
      configurator_type: this.activeTypes,
      active: true,
      is_default: false,
    });
    $('#configurationModal').modal('show');
  }
  updateItem(item, index) {
    this.submitted = false;
    this.isAddData = false;
    this.itemIndex = index;
    console.log('itemIndex', this.itemIndex);
    this.labConfigurationForm.reset({
      id: item.id,
      code: item.code,
      description: item.description,
      configurator_type: item.configurator_type,
      active: item.active,
      is_default: item.is_default,
    });
    $('#configurationModal').modal('show');
  }
  onSubmit(): any {
    this.submitted = true;
    console.log(this.labConfigurationForm.value);
    if (this.labConfigurationForm.invalid) {
      return;
    }
    if (this.isAddData) {
      this.addItemSubmit();
    } else {
      this.updateItemSubmit();
    }
  }
  addItemSubmit() {
    this.ngxLoader.start();
    this.labService.createConfigV2(this.labConfigurationForm.value).subscribe(
      (data) => {
        this.labConfigurations.forEach((config) => (config.is_default = false));
        this.labConfigurations.unshift(data);
        this.ngxLoader.stop();
        toastr.success('Saved Successfully');
        $('#configurationModal').modal('hide');
      },
      (error) => {
        this.ngxLoader.stop();
        toastr.error(error.error.message);
      },
    );
  }

  updateItemSubmit() {
    this.ngxLoader.start();
    this.labService
      .updateLabConfiguratorsV2(this.labConfigurationForm.value)
      .subscribe(
        (data) => {
          this.labConfigurations.forEach(
            (config) => (config.is_default = false),
          );
          this.labConfigurations[this.itemIndex] = data;
          this.ngxLoader.stop();
          toastr.success('Update Successfully');
          $('#configurationModal').modal('hide');
        },
        (error) => {
          this.ngxLoader.stop();
          toastr.error(error.error.message);
        },
      );
  }
  searchResultUpdate(): any {
    this.searchCode = '';
    this.searchStatus = 'true';
    this.page = 1;
    this.perPage = 25;
    this.fetchLabConfigurators();
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.fetchLabConfigurators();
  }
  loadPage(event): any {
    this.fetchLabConfigurators();
  }
  get controls(): any {
    return this.labConfigurationForm.controls;
  }
}
