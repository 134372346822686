import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { OpdService } from '../../../../services/opd.service';
import { PatientService } from '../../../../services/patient.service';
import { ServiceService } from '../../../../services/service.service';
import { PricingService } from '../../../../services/pricing.service';
import { DepartmentsbyhospitalService } from '../../../../services/departmentsbyhospital.service';
import { LookupService } from '../../../../services/lookup.service';
import { EmployeeRegistrationService } from '../../../../services/employee-registration.service';
import { Router } from '@angular/router';
import { SystemControlsService } from '../../../../services/system-controls.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-consultations',
  templateUrl: './consultations.component.html',
  styleUrls: ['./consultations.component.css'],
})
export class ConsultationsComponent implements OnInit {
  @Input() patient: any;
  @Input() doctor: any;
  @Input() visitTypes: any;
  @Input() status: any;
  @Input() doctors: any;
  @Input() isAdd: boolean;
  @Input() isipd: boolean;
  @Input() isopd: boolean;
  @Input() isemr: boolean;
  @Input() isConsultationReceived: number;

  consultationForm: FormGroup;
  // doctor = [];
  isSubmit = false;
  allowServiceZeroPrice = false;
  // doctors = [];
  panelType = false;
  visitType = [];
  attendVisit = [];
  allConsultations = [];
  patientAllHistory = false;
  currentControls: any;
  current_page: any;

  serviceFormConsultantVisit = {
    patient_services: [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        price: 0,
        discount: 0,
        service_price: 0,
        actual_price: 0,
        quantity: 0,
        urgent: false,
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
      },
    ],
    discount: 0,
    cash: 0,
    ledger: '',
    mode_of_payment: 'Cash',
    payment_details: '',
    receivable: 0,
    patient_id: '',
  };
  department: any;
  doctorId: any;
  doctor_ids: any;
  departmentId: any;
  department_ids: any;

  constructor(
    private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private hospitalDoctorsService: HospitaldoctorService,
    private opdService: OpdService,
    private serviceService: ServiceService,
    private pricingService: PricingService,
    private departmentService: DepartmentsbyhospitalService,
    private lookupService: LookupService,
    private employeeService: EmployeeRegistrationService,
    private router: Router,
    private patientService: PatientService,
    private systemControlService: SystemControlsService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      $('#consultingDetails').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
    }, 500);

    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (
        this.currentControls.patient_services.allow_zero_service_price === true
      ) {
        this.allowServiceZeroPrice = true;
      }
    });

    this.consultationForm = this.formBuilder.group({
      doctor_id: ['', Validators.required],
      department_id: [''],
      visit_type_id: ['', Validators.required],
      doctor_type_id: ['', Validators.required],
      patient_id: [''],
      bill: [false],
      detail: [''],
      status: [''],
    });

    // this.fetchLookup();
    this.fetchAttendingVisiting();
    this.fetchDepartments();
    setTimeout(() => {
      $('#ipdConsultingdoctor').selectpicker('refresh');
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.isConsultationReceived?.currentValue) {
      this.fetchConsultations('');
    }
    this.fetchConsultations(changes.patient?.currentValue);
  }

  createVisit(): any {
    const paymentStatus = 'unpaid';
    this.ngxLoader.start();
    this.serviceFormConsultantVisit.patient_id = this.patient.id;
    if (this.isemr) {
      this.current_page = 'emr-v2';
    } else if (this.isopd) {
      this.current_page = 'opd-v2';
    } else if (this.isipd) {
      this.current_page = 'ipd-v2';
    }
    this.patientService
      .createPatientServiceV2(
        this.serviceFormConsultantVisit,
        this.patient,
        paymentStatus,
        '',
        '',
        '',
        '',
        this.current_page,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  onSubmitConsultations(value): any {
    this.isSubmit = true;
    if (this.consultationForm.invalid) {
      return;
    }
    if (
      this.serviceFormConsultantVisit.patient_services[0].service_price === 0 &&
      this.consultationForm.get('bill').value === true &&
      this.allowServiceZeroPrice === false
    ) {
      toastr.error(
        'This service is not configured. Please contact IT Administrator',
      );
      return;
    }

    if (this.consultationForm.get('bill').value === true) {
      this.createVisit();
    }

    this.consultationForm.get('patient_id').patchValue(this.patient.id);
    if (this.isipd) {
      this.consultationForm.get('status').patchValue('ipd');
    } else {
      this.consultationForm.get('status').patchValue('opd');
    }
    if (value !== 'main' && $('#consultingDetails').summernote('isEmpty') === false) {
      this.consultationForm
        .get('detail')
        .setValue($('#consultingDetails').summernote('code'));
    } else{
      this.consultationForm
        .get('detail')
        .setValue('');
    }

    this.isSubmit = true;
    if (this.consultationForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .addConsultingV2(this.consultationForm.value, false, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (value !== 'main') {
            $('#consultingDetails').summernote('code', '');
            this.resetConsultationForm();
            this.fetchConsultations('');
            // this.fetchLookup();
          }
          toastr.success('Consultation Notes Added');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchDoctorDepartments(id): any {
    if (id) {
      this.serviceFormConsultantVisit.patient_services[0].doctor_id = id;
      const dep_id = this.doctors.filter((t) => t.id === Number(id))[0]
        .doctor_department_id;
      this.doctorId = id;
      this.departmentId = dep_id;
      this.fetchLookup(id, dep_id);
      if (dep_id) {
        this.serviceFormConsultantVisit.patient_services[0].department_id =
          dep_id;
        this.consultationForm.get('department_id').patchValue(dep_id);
      } else {
        this.consultationForm.get('department_id').patchValue('');
      }

      setTimeout(() => {
        $('#ipdConsultingDepartment').selectpicker('refresh');
        $('#ipdConsultingDepartments').selectpicker('refresh');
      }, 500);
      setTimeout(() => {
        $('#ipdConsultingDepartment').selectpicker();
        $('#ipdConsultingDepartments').selectpicker();
      }, 500);
    }
  }

  fetchVisitService(val): any {
    this.ngxLoader.start();
    this.serviceService
      .getVisitServiceV3(
        this.patient.id,
        this.consultationForm.get('visit_type_id').value,
        this.consultationForm.get('doctor_id').value,
        this.consultationForm.get('department_id').value,
        this.isopd,
        this.isipd,
        this.isemr,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.serviceFormConsultantVisit.patient_services[0].service_id =
            data.service.id;
          this.serviceFormConsultantVisit.patient_services[0].billing_code =
            data.service.billing_code;
          this.fetchConsultationPrice();
        },
        (err) => {
          this.ngxLoader.stop();
          // this.updatePrice();
          toastr.error(err.error.message);
        },
      );
  }

  fetchConsultationPrice(): any {
    const obj = {
      patient_type_id: this.patient.patient_type.id,
      current_billing_code:
        this.serviceFormConsultantVisit.patient_services[0].billing_code,
      // current_billing_code: ServiceForm.this.patient_ser[0].billing_code,
    };
    this.ngxLoader.start();
    this.pricingService.searchPriceV3(obj).subscribe(
      (data) => {
        let discount;
        if (
          this.patient.admissions.length > 0 &&
          this.patient.admitted === true
        ) {
          this.serviceFormConsultantVisit.patient_services[0].price =
            data.pricings[0].ipd_price;
          this.serviceFormConsultantVisit.patient_services[0].service_price =
            data.pricings[0].ipd_price;
          this.serviceFormConsultantVisit.patient_services[0].actual_price =
            data.pricings[0].ipd_price;
          this.serviceFormConsultantVisit.patient_services[0].pricing_id =
            data.pricings[0].id;
          discount =
            (data.pricings[0].ipd_price / 100) *
            data.pricings[0].system_discount_percentage;
        } else {
          this.serviceFormConsultantVisit.patient_services[0].price =
            data.pricings[0].opd_price;
          this.serviceFormConsultantVisit.patient_services[0].service_price =
            data.pricings[0].opd_price;
          this.serviceFormConsultantVisit.patient_services[0].actual_price =
            data.pricings[0].opd_price;
          this.serviceFormConsultantVisit.patient_services[0].pricing_id =
            data.pricings[0].id;
          discount =
            (data.pricings[0].opd_price / 100) *
            data.pricings[0].system_discount_percentage;
        }
        this.serviceFormConsultantVisit.patient_services[0].discount =
          Math.round(discount);
        this.serviceFormConsultantVisit.patient_services[0].pricing_id =
          data.pricings[0].id;
        this.serviceFormConsultantVisit.patient_services[0].quantity = 1;
        if (this.panelType === true) {
        } else {
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        this.serviceFormConsultantVisit.patient_services[0].service_price = 0;
      },
    );
  }

  fetchDeptDoctors(id): any {
    this.serviceFormConsultantVisit.patient_services[0].service_id = '';
    this.serviceFormConsultantVisit.patient_services[0].billing_code = '';
    this.serviceFormConsultantVisit.patient_services[0].pricing_id = '';
    this.serviceFormConsultantVisit.patient_services[0].price = 0;
    this.serviceFormConsultantVisit.patient_services[0].discount = 0;
    this.serviceFormConsultantVisit.patient_services[0].doctor_id = '';

    this.doctor = [];
    this.fetchLookup('', id);
    const objId = '#ipdConsultingdoctor';
    const objIds = '#ipdConsultingdoctors';
    setTimeout(() => {
      $(objId).selectpicker('refresh');
      $(objIds).selectpicker('refresh');
    }, 500);
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(id).subscribe(
      (data) => {
        this.doctor = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $(objId).selectpicker('refresh');
          $(objIds).selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $(objId).selectpicker();
          $(objIds).selectpicker();
        }, 500);
        this.consultationForm.get('doctor_id').patchValue('');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  get f(): any {
    return this.consultationForm.controls;
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.indexDepartmentsv2().subscribe(
      (data) => {
        this.department = data.departments;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.departSelectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchLookup(id, dep_id): any {
    this.ngxLoader.start();
    this.doctor_ids = id;
    this.department_ids = dep_id;
    this.lookupService
      .indexVisitTypeV2(
        this.doctor_ids,
        this.department_ids,
        this.isopd,
        this.isipd,
        this.patient.id,
      )
      .subscribe(
        (data) => {
          this.visitType = data.lookups;
          this.consultationForm
            .get('visit_type_id')
            .setValue(
              this.visitType.filter(
                (t) => t.key === 'Normal' || t.key === 'General',
              )[0]?.id || '',
            );
          if (
            this.visitType.filter(
              (t) => t.key === 'Normal' || t.key === 'General',
            )[0]?.id
          ) {
            this.fetchVisitService('');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  fetchAttendingVisiting(): any {
    this.employeeService.fetchConfigurationV2('doctor_type').subscribe(
      (data) => {
        this.attendVisit = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchConsultations(val): any {
    this.ngxLoader.start();
    this.allConsultations = [];
    if (this.visitTypes === 'both') {
      this.visitTypes = '';
    }
    this.opdService
      .getConsultationsV2(
        this.patient,
        this.status,
        this.patientAllHistory,
        this.visitTypes,
      )
      .subscribe(
        (data) => {
          this.allConsultations = data.ipd_doctors;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  printViewConsul(): any {
    this.router.navigate(['pdf-reports/consultant-patient-history'], {
      queryParams: {
        id: this.patient.id,
        mrn: this.patient.mrn,
        type: 'ipd',
        visit_id: this.patient.visit_id,
        invoice_id: '',
      },
    });
  }
  printViewConsulVisits(id, status): any {
    this.router.navigate(['pdf-reports/consultant-patient-history'], {
      queryParams: {
        id: this.patient.id,
        mrn: this.patient.mrn,
        visit_id: this.patient.visit_id,
        consultantId: id,
        type: status,
      },
    });
  }
  resetConsultationForm(): any {
    $('#consultingDetails').summernote('code', '');
    this.consultationForm.reset({
      doctor_id: '',
      department_id: '',
      visit_type_id: '',
      doctor_type_id: '',
      bill: false,
      detail: '',
    });
    this.isSubmit = false;

    this.doctor = [];
    const objId = '#ipdConsultingdoctor';
    const objIds = '#ipdConsultingdoctors';
    const departId = '#ipdConsultingDepartment';
    setTimeout(() => {
      $(objId).selectpicker('refresh');
      $(objIds).selectpicker('refresh');
      $(departId).selectpicker('refresh');
    }, 500);
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchOpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctor = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $(objId).selectpicker('refresh');
          $(objIds).selectpicker('refresh');
          $(departId).selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $(objId).selectpicker();
          $(objIds).selectpicker();
          $(departId).selectpicker();
        }, 500);
        this.consultationForm.get('doctor_id').patchValue('');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
}
