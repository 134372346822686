import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigurationService } from '../../../services/configuration.service';
import { SystemControlsService } from '../../../services/system-controls.service';

declare var toastr: any;

@Component({
  selector: 'app-radiology-pacs-url-v2',
  templateUrl: './radiology-pacs-url-v2.component.html',
  styleUrls: ['./radiology-pacs-url-v2.component.css'],
})
export class RadiologyPacsUrlV2Component implements OnInit {
  packsUrlForm: FormGroup;
  isSubmit = false;
  servicesControl = [];
  constructor(
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private configService: ConfigurationService,
    private systemControlService: SystemControlsService,
  ) {}

  ngOnInit(): void {
    this.packsUrlForm = this.fb.group({
      id: [''],
      radiant_url: ['', Validators.required],
      web_url: ['', Validators.required],
      default_pacs: ['', Validators.required],
      header_height: [''],
      footer_height: [''],
      rad_status: [''],
      show_worklist: [1],
    });
    this.getRadiologyPACSUrls();
    this.getServicesControl();
  }
  getRadiologyPACSUrls(): any {
    this.configService.getPACSUrlV2().subscribe(
      (data) => {
        if (data.radiology_configurator) {
          this.packsUrlForm.patchValue({
            id: data.radiology_configurator.id,
            radiant_url: data.radiology_configurator.radiant_url,
            web_url: data.radiology_configurator.web_url,
            default_pacs: data.radiology_configurator.default_pacs
              ? data.radiology_configurator.default_pacs
              : '',
            header_height: data.radiology_configurator.header_height,
            footer_height: data.radiology_configurator.footer_height,
            rad_status: data.radiology_configurator.rad_status,
            show_worklist: data.radiology_configurator.show_worklist,
          });
        } else {
          toastr.error(data.message);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  submit(): any {
    this.isSubmit = true;
    if (this.packsUrlForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.configService.addPACSUrlV2(this.packsUrlForm.value).subscribe(
      (data) => {
        this.isSubmit = false;
        this.packsUrlForm.reset();
        this.getRadiologyPACSUrls();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getServicesControl(): any {
    this.systemControlService.getRadiologyControlV2().subscribe(
      (data) => {
        this.servicesControl = data.rad_controls;
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  updateServicesControl(): any {
    this.ngxLoader.start();
    this.systemControlService
      .updateRadiologyControlv2(this.servicesControl)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }

  get control(): any {
    return this.packsUrlForm.controls;
  }
}
