import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppComponent } from '../app.component';
import { LabTestService } from '../services/lab-test.service';
import { environment } from '../../environments/environment';
import { HospitalService } from '../services/hospital.service';
import { ActivatedRoute } from '@angular/router';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-online-lab-report-v2',
  templateUrl: './online-lab-report-v2.component.html',
  styleUrls: ['./online-lab-report-v2.component.css'],
})
export class OnlineLabReportV2Component implements OnInit {
  listData: any;
  environment = environment;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  currentEnvironment: string;
  homeImage = '';
  patient: any;
  subDomain: any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: AuthService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private appComponent: AppComponent,
    private labTestService: LabTestService,
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getSettingsData();
    this.loginForm = this.formBuilder.group({
      mrn: ['', Validators.required],
      phone1: ['', Validators.required],
      passcode: ['', Validators.required],
    });
    this.currentEnvironment = environment.rorUrl;
    const currentUrl = window.location.href;
    this.subDomain = this.getSubdomainFromUrl(currentUrl);
  }

  getSubdomainFromUrl(url: string): string {
    const hostname = new URL(url).hostname;
    const ipAddressPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

    if (ipAddressPattern.test(hostname)) {
      return '';
    } else {
      const parts = hostname.split('.');
      if (parts.length > 2) {
        return parts[0];
      } else {
        return '';
      }
    }
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitHomeImageV2('').subscribe(
      (data) => {
        this.homeImage = data.homepage_image;
      },
      (err) => {},
    );
  }

  createSession(): any {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.userService
      .loginPatientV2(
        this.loginForm.value.mrn,
        this.loginForm.value.phone1,
        this.loginForm.value.passcode,
        this.subDomain,
      )
      .subscribe(
        (data) => {
          this.patient = data;
          localStorage.setItem('auth_token', '');
          localStorage.setItem(
            'current_medical_unit_id',
            this.patient?.medical_unit_id,
          );
          localStorage.setItem('is_session_expired', 'false');
          this.fetchLabsReportsTestList(data.mrn);
          this.ngxLoader.stop();
          toastr.success('Successfully Logged In.');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  fetchLabsReportsTestList(patientId): any {
    this.ngxLoader.start();
    this.labTestService
      .fetchPatientLabReportsV2(patientId, this.patient?.medical_unit_id)
      .subscribe(
        (data) => {
          this.listData = data.orders;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  printReport(id, type, mrn): any {
    if (type === 'Radiology') {
      window.open(
        '/rad-report-pdf?id=' +
          id +
          '&mrn=' +
          mrn +
          '&visit_id=' +
          '&show_header=true' +
          '&show_footer=true',
      );
    } else {
      window.open(
        '/lab-report-pdf?' +
          'lab_id=' +
          id +
          '&id=' +
          '&visit_id=' +
          '&mrn=' +
          mrn +
          '&show_header=true' +
          '&show_footer=true',
      );
    }
  }

  get f(): any {
    return this.loginForm.controls;
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
